import React from 'react';

const CheckMark = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Green checkmark"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 8"
    {...props}>
    <path d="m4.273 7 6-6M4.273 7 1 3.727" stroke="#00CFAA" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default CheckMark;

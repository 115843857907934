import {useEffect, useState} from 'react';

import jarvisStorage, {JarvisStorageValues} from '@src/utils/storage/JarvisStorage';

const useJarvisStorageValue = <T extends keyof JarvisStorageValues>(key: T, initialValue?: JarvisStorageValues[T]) => {
  const [value, set] = useState<JarvisStorageValues[T] | undefined>(jarvisStorage.getItem(key) ?? initialValue);

  useEffect(() => {
    if (value) {
      jarvisStorage.setItem(key, value);
    }
    if (value === undefined) {
      jarvisStorage.removeItem(key);
    }
  }, [value, key]);

  return [value, set] as const;
};

export default useJarvisStorageValue;

import React from 'react';

const Dashboard = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Three rounded rectangles divided with 2 rectangles above each other on left and the biggest one on the right"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18"
    {...props}>
    <rect x=".5" y=".5" width="8.333" height="4.333" rx="2.167" stroke="currentColor" />
    <rect x=".5" y="7.167" width="8.333" height="9.667" rx="3.5" stroke="currentColor" />
    <rect x="11.5" y=".5" width="7.667" height="16.333" rx="3.5" stroke="currentColor" />
  </svg>
);

export default Dashboard;

import React from 'react';

const SearchCircle = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Magnifying glass inside a circle"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102 102"
    {...props}>
    <circle r="50.834" transform="matrix(1 0 0 -1 50.834 50.834)" fill="#fff" />
    <circle
      cx="46.084"
      cy="49.084"
      r="17.037"
      transform="rotate(33.39 46.084 49.084)"
      stroke="#9FC2F9"
      strokeWidth=".697"
    />
    <path d="M58.96 60.217 75 72.42" stroke="#9FC2F9" strokeWidth=".697" />
  </svg>
);

export default SearchCircle;

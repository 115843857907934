import '@jarvis/token/lib/index.css';
import {ThemeProvider} from '@emotion/react';
import {CUSTOM_FETCHER} from '@jarvis/swagger';
import {Providers} from 'join-react-context';
import type {AppProps /*, AppContext */} from 'next/app';
import Head from 'next/head';
import React, {useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

import {GTM_ENV, JARVIS_API_HOST, logBuildInformation} from '@src/config';
import {AuthProvider} from '@src/contexts/AuthContext';
import {ClassroomIdProvider} from '@src/contexts/ClassroomIdContext';
import {CreateAssignmentProvider} from '@src/contexts/CreateAssignmentContext';
import GlobalStyles from '@src/styles/global';
import theme from '@src/styles/theme';
import type {Page} from '@src/types/page';
import {initSentry} from '@src/utils/sentry';
import jarvisStorage from '@src/utils/storage/JarvisStorage';
import '@src/styles/content-viewer.css';

if (typeof window !== 'undefined') {
  TagManager.initialize(GTM_ENV);
}

const queryClient = new QueryClient();

CUSTOM_FETCHER.setBaseUrl(JARVIS_API_HOST);
CUSTOM_FETCHER.setHeaders(() => {
  const authInfo = jarvisStorage.getItem('authInfo');
  return Promise.resolve(
    authInfo
      ? {
          Authorization: `bearer ${authInfo.accessToken}`,
        }
      : ({} as Record<string, string>)
  );
});

type MyAppProps = AppProps & {
  Component: Page;
};

initSentry();

const App = ({Component, pageProps}: MyAppProps) => {
  const PageLayout = Component.layout ?? React.Fragment;

  useEffect(() => {
    logBuildInformation();
  }, []);

  return (
    <Providers
      providers={
        <>
          <QueryClientProvider client={queryClient} />
          <AuthProvider />
          <ClassroomIdProvider />
          <ThemeProvider theme={theme} />
          <CreateAssignmentProvider />
        </>
      }>
      <Head>
        <title>Riiid for Classrooms</title>
        <meta name="description" content="A platform that puts teachers first" />
      </Head>
      <ReactQueryDevtools initialIsOpen={false} />
      <GlobalStyles theme={theme} />
      <PageLayout>
        <Component {...pageProps} />
      </PageLayout>
    </Providers>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default App;

import React from 'react';

const AttentionX = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Red circle icon with a X in the middle"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    {...props}>
    <circle cx="15" cy="15" r="15" fill="#FFE2E9" />
    <rect
      x="17.699"
      y="17.003"
      width=".986"
      height="6.65"
      rx=".493"
      transform="rotate(135 17.7 17.003)"
      fill="#FF6C90"
    />
    <rect width=".986" height="6.65" rx=".493" transform="scale(1 -1) rotate(-45 -14.375 -23.347)" fill="#FF6C90" />
    <circle cx="15" cy="15" r="8.545" stroke="#FF6C90" strokeWidth=".909" />
  </svg>
);

export default AttentionX;

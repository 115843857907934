import React from 'react';

const AlertTriangle = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Red triangle with exclamation mark inside."
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 14"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.223.016a1.672 1.672 0 0 0-.97.513c-.196.198-.349.456-3.12 5.26A733.835 733.835 0 0 0 .15 10.984c-.115.243-.15.42-.15.745 0 .221.016.354.055.47.208.617.633 1.038 1.236 1.223.169.052.435.054 6.209.054s6.04-.002 6.209-.054c.603-.185 1.028-.606 1.236-1.223.039-.116.054-.249.055-.47 0-.324-.035-.502-.15-.744-.037-.079-1.379-2.417-2.982-5.196C9.005.827 8.949.732 8.733.515c-.33-.33-.67-.483-1.145-.512a1.914 1.914 0 0 0-.365.013Zm.493.896a.874.874 0 0 1 .495.327c.043.062 1.373 2.358 2.958 5.103 1.833 3.177 2.897 5.049 2.926 5.15.12.41-.125.89-.534 1.047-.142.055-.3.056-6.061.056-5.76 0-5.92-.001-6.061-.056a.992.992 0 0 1-.489-.466c-.096-.214-.095-.538.002-.725.262-.508 5.846-10.13 5.924-10.207a.971.971 0 0 1 .44-.242c.162-.038.212-.036.4.013Zm-.656 5.68v2.202h.88V4.39h-.88v2.202Zm.24 3.114c-.14.042-.319.235-.36.385a.605.605 0 0 0 .257.673c.167.104.453.1.617-.009.183-.121.258-.265.258-.494 0-.226-.074-.372-.25-.488a.719.719 0 0 0-.521-.067Z"
      fill="currentColor"
    />
  </svg>
);

export default AlertTriangle;

import React from 'react';

const SearchCircleBlue = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Magnifying glass inside a light blue circle"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102 102"
    {...props}>
    <circle r="50.8343" transform="matrix(1 0 0 -1 50.8343 50.8341)" fill="#EBF3FF" />
    <circle
      cx="48.0843"
      cy="50.7527"
      r="16.6359"
      transform="rotate(33.3895 48.0843 50.7527)"
      stroke="#9FC2F9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.9603 61.8853L76.9997 74.0892"
      stroke="#9FC2F9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchCircleBlue;

import React from 'react';

import {PerformanceMeterVariant} from '@src/components/atoms/PerformanceMeter';

export interface MeterAverageProps extends React.SVGAttributes<HTMLOrSVGElement> {
  variant?: PerformanceMeterVariant;
}

const MeterAverage = ({variant = 'dashboard', ...props}: MeterAverageProps) => {
  switch (variant) {
    case 'student':
      return (
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 51"
          role="img"
          aria-label="Triangle divided into 3 parts, with the middle part painted in blue"
          {...props}>
          <path
            opacity=".4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.31 49.385H37.413v-26.29l22.898-11.909v38.2zm6.103 0h16.36a4.577 4.577 0 004.578-4.577V4.663c0-3.435-3.642-5.646-6.689-4.06l-14.249 7.41v41.372zM31.311 26.27v23.116H5.58c-4.79 0-6.361-6.427-2.111-8.637l27.84-14.48z"
            fill="#D6D6D6"
          />
          <path
            d="M55.069 51H41.926c-2.722 0-4.928-1.69-4.928-3.774V19.227c0-1.685 1.458-3.166 3.574-3.628L53.715 9.87c3.148-.689 6.283 1.122 6.283 3.629v33.726c0 2.084-2.207 3.774-4.929 3.774z"
            fill="#0065FF"
          />
        </svg>
      );
    default:
      return (
        <svg
          viewBox="0 0 56 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Triangle divided into 3 parts, with the middle part painted in blue"
          {...props}>
          <path
            opacity=".4"
            d="M35.993 4.382l-15 3.65V18.5h15V4.382zm4 14.118h11a3 3 0 003-3V3.818a3 3 0 00-3.71-2.915l-10.29 2.505V18.5zm-23-9.493V18.5H3.002c-3.549 0-4.158-5.076-.71-5.915l14.701-3.578z"
            fill="#D6D6D6"
          />
          <path
            d="M34 19H23a3 3 0 01-3-3v-5.755a3 3 0 012.154-2.878l11-3.236A3 3 0 0137 7.01V16a3 3 0 01-3 3z"
            fill="#0065FF"
          />
        </svg>
      );
  }
};

export default MeterAverage;

import React from 'react';

const Folder = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <rect x="9.166" width="10.833" height="17.5" rx="2.043" fill="#245BCB" />
    <path
      d="M0 5.376c0-1.128.914-2.043 2.043-2.043H20v13.791a2.043 2.043 0 01-2.043 2.043H2.043A2.043 2.043 0 010 17.124V5.376z"
      fill="#4EAFAA"
    />
  </svg>
);

export default Folder;

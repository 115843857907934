import React from 'react';

const LateSubmission = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg role="img" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM7.36612 17.1339C6.87796 16.6457 6.87796 15.8543 7.36612 15.3661L10.4822 12.25L7.36612 9.13388C6.87796 8.64573 6.87796 7.85427 7.36612 7.36612C7.85427 6.87796 8.64573 6.87796 9.13388 7.36612L12.25 10.4822L15.3661 7.36612C15.8543 6.87796 16.6457 6.87796 17.1339 7.36612C17.622 7.85427 17.622 8.64573 17.1339 9.13388L14.0178 12.25L17.1339 15.3661C17.622 15.8543 17.622 16.6457 17.1339 17.1339C16.6457 17.622 15.8543 17.622 15.3661 17.1339L12.25 14.0178L9.13388 17.1339C8.64573 17.622 7.85427 17.622 7.36612 17.1339Z"
      fill="#E62B76"
    />
  </svg>
);

export default LateSubmission;

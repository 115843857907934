import React from 'react';

const FolderOutlineOpen = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Opened folder with an outline style"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.494.285A2.494 2.494 0 000 2.78v12.842a2 2 0 00.74 1.555c.255.334.665.54 1.177.54h13.012c.201 0 .403-.033.6-.091h.124a2.015 2.015 0 000-.003h-.115c.835-.251 1.586-.987 1.815-1.855l2.498-9.459c.285-1.076-.34-1.948-1.395-1.948H16.94a1.995 1.995 0 00-1.287-.468H9.356V2.779A2.494 2.494 0 006.862.285H2.494zM8.375 4.36H5.444c-1.055 0-2.14.872-2.425 1.948L.981 14.027V2.779c0-.835.677-1.513 1.513-1.513h4.368c.836 0 1.513.678 1.513 1.513v1.58zM.56 15.622l-.038.145a2.068 2.068 0 01.018-.074l.02-.07zM5.444 5.35h13.012c.278 0 .404.108.455.179.051.072.115.229.04.512L16.453 15.5c-.171.648-.889 1.225-1.524 1.225H1.917c-.278 0-.404-.108-.455-.18-.051-.07-.115-.228-.04-.511l2.498-9.46c.171-.647.888-1.224 1.524-1.224z"
      fill="currentColor"
    />
  </svg>
);

export default FolderOutlineOpen;

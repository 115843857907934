import React from 'react';

const TrashCircleIcon = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg viewBox="0 0 92 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="46" cy="45.2734" r="45.2734" fill="#FF6C90" />
    <path
      d="M28.9019 28.6177L31.348 64.4911C31.541 67.3214 33.8932 69.5186 36.73 69.5186H54.9227C57.7596 69.5186 60.1117 67.3214 60.3047 64.4911L62.7509 28.6177"
      stroke="white"
      strokeWidth="2"
    />
    <mask id="path-3-inside-1_4_2174" fill="white">
      <path d="M23.2969 29.3882C23.2969 28.8359 23.7446 28.3882 24.2969 28.3882H67.4289C67.9812 28.3882 68.4289 28.8359 68.4289 29.3882V29.3882C68.4289 29.9405 67.9812 30.3882 67.4289 30.3882H24.2969C23.7446 30.3882 23.2969 29.9405 23.2969 29.3882V29.3882Z" />
    </mask>
    <path
      d="M23.2969 29.3882C23.2969 28.8359 23.7446 28.3882 24.2969 28.3882H67.4289C67.9812 28.3882 68.4289 28.8359 68.4289 29.3882V29.3882C68.4289 29.9405 67.9812 30.3882 67.4289 30.3882H24.2969C23.7446 30.3882 23.2969 29.9405 23.2969 29.3882V29.3882Z"
      fill="#333333"
    />
    <path
      d="M24.2969 30.3882H67.4289V26.3882H24.2969V30.3882ZM67.4289 28.3882H24.2969V32.3882H67.4289V28.3882ZM24.2969 28.3882C24.8492 28.3882 25.2969 28.8359 25.2969 29.3882H21.2969C21.2969 31.045 22.64 32.3882 24.2969 32.3882V28.3882ZM66.4289 29.3882C66.4289 28.8359 66.8767 28.3882 67.4289 28.3882V32.3882C69.0858 32.3882 70.4289 31.045 70.4289 29.3882H66.4289ZM67.4289 30.3882C66.8767 30.3882 66.4289 29.9405 66.4289 29.3882H70.4289C70.4289 27.7313 69.0858 26.3882 67.4289 26.3882V30.3882ZM24.2969 26.3882C22.64 26.3882 21.2969 27.7313 21.2969 29.3882H25.2969C25.2969 29.9405 24.8492 30.3882 24.2969 30.3882V26.3882Z"
      fill="white"
      mask="url(#path-3-inside-1_4_2174)"
    />
    <mask id="path-5-inside-2_4_2174" fill="white">
      <path d="M40.9531 38.491C41.4666 38.491 41.8828 38.9072 41.8828 39.4206L41.8828 59.4863C41.8828 59.9997 41.4666 60.416 40.9531 60.416V60.416C40.4397 60.416 40.0235 59.9997 40.0235 59.4863L40.0235 39.4206C40.0235 38.9072 40.4397 38.491 40.9531 38.491V38.491Z" />
    </mask>
    <path
      d="M40.9531 38.491C41.4666 38.491 41.8828 38.9072 41.8828 39.4206L41.8828 59.4863C41.8828 59.9997 41.4666 60.416 40.9531 60.416V60.416C40.4397 60.416 40.0235 59.9997 40.0235 59.4863L40.0235 39.4206C40.0235 38.9072 40.4397 38.491 40.9531 38.491V38.491Z"
      fill="#333333"
    />
    <path
      d="M39.8828 39.4206L39.8828 59.4863L43.8828 59.4863L43.8828 39.4206L39.8828 39.4206ZM42.0235 59.4863L42.0235 39.4206L38.0235 39.4206L38.0235 59.4863L42.0235 59.4863ZM42.0235 39.4206C42.0235 40.0118 41.5443 40.491 40.9531 40.491L40.9531 36.491C39.3351 36.491 38.0235 37.8026 38.0235 39.4206L42.0235 39.4206ZM40.9531 58.416C41.5443 58.416 42.0235 58.8952 42.0235 59.4863L38.0235 59.4863C38.0235 61.1043 39.3351 62.416 40.9531 62.416L40.9531 58.416ZM39.8828 59.4863C39.8828 58.8952 40.362 58.416 40.9531 58.416L40.9531 62.416C42.5711 62.416 43.8828 61.1043 43.8828 59.4863L39.8828 59.4863ZM43.8828 39.4206C43.8828 37.8026 42.5711 36.491 40.9531 36.491L40.9531 40.491C40.362 40.491 39.8828 40.0118 39.8828 39.4206L43.8828 39.4206Z"
      fill="white"
      mask="url(#path-5-inside-2_4_2174)"
    />
    <mask id="path-7-inside-3_4_2174" fill="white">
      <path d="M49.4146 38.491C49.928 38.491 50.3442 38.9072 50.3442 39.4206L50.3442 59.4863C50.3442 59.9997 49.928 60.416 49.4146 60.416V60.416C48.9011 60.416 48.4849 59.9997 48.4849 59.4863L48.4849 39.4206C48.4849 38.9072 48.9011 38.491 49.4146 38.491V38.491Z" />
    </mask>
    <path
      d="M49.4146 38.491C49.928 38.491 50.3442 38.9072 50.3442 39.4206L50.3442 59.4863C50.3442 59.9997 49.928 60.416 49.4146 60.416V60.416C48.9011 60.416 48.4849 59.9997 48.4849 59.4863L48.4849 39.4206C48.4849 38.9072 48.9011 38.491 49.4146 38.491V38.491Z"
      fill="#333333"
    />
    <path
      d="M48.3442 39.4206L48.3442 59.4863L52.3442 59.4863L52.3442 39.4206L48.3442 39.4206ZM50.4849 59.4863L50.4849 39.4206L46.4849 39.4206L46.4849 59.4863L50.4849 59.4863ZM50.4849 39.4206C50.4849 40.0118 50.0057 40.491 49.4146 40.491L49.4146 36.491C47.7965 36.491 46.4849 37.8026 46.4849 39.4206L50.4849 39.4206ZM49.4146 58.416C50.0057 58.416 50.4849 58.8952 50.4849 59.4863L46.4849 59.4863C46.4849 61.1043 47.7965 62.416 49.4146 62.416L49.4146 58.416ZM48.3442 59.4863C48.3442 58.8952 48.8234 58.416 49.4146 58.416L49.4146 62.416C51.0326 62.416 52.3442 61.1043 52.3442 59.4863L48.3442 59.4863ZM52.3442 39.4206C52.3442 37.8026 51.0326 36.491 49.4146 36.491L49.4146 40.491C48.8234 40.491 48.3442 40.0118 48.3442 39.4206L52.3442 39.4206Z"
      fill="white"
      mask="url(#path-7-inside-3_4_2174)"
    />
    <path
      d="M52.5013 28.5172C52.5013 24.9179 49.5834 22 45.984 22C42.3847 22 39.4668 24.9179 39.4668 28.5172"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default TrashCircleIcon;

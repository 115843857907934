import React from 'react';

const Paper = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg role="img" aria-label="Paper" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 17" {...props}>
    <rect x=".5" y=".5" width="13.143" height="16" rx="3.5" stroke="currentColor" />
    <rect
      x="11.166"
      y="5.666"
      width="1.214"
      height="8.188"
      rx=".607"
      transform="rotate(90 11.166 5.666)"
      fill="currentColor"
    />
    <rect
      x="11.166"
      y="10.524"
      width="1.214"
      height="8.188"
      rx=".607"
      transform="rotate(90 11.166 10.524)"
      fill="currentColor"
    />
  </svg>
);

export default Paper;

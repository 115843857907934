import React from 'react';

const AttentionF = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Red circle icon with a F in the middle"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    {...props}>
    <circle cx="15" cy="15" r="15" fill="#FFE2E9" />
    <rect x="14" y="19" width="1" height="7" rx=".5" transform="rotate(-180 14 19)" fill="#FF6C90" />
    <rect width=".986" height="5" rx=".493" transform="matrix(0 1 1 0 13 12)" fill="#FF6C90" />
    <rect width="1" height="3" rx=".5" transform="matrix(0 1 1 0 13 15)" fill="#FF6C90" />
    <circle cx="15" cy="15" r="8.545" stroke="#FF6C90" strokeWidth=".909" />
  </svg>
);

export default AttentionF;

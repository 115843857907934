import React from 'react';

import * as S from './CreateAssignmentFormSection.styles';

export interface CreateAssignmentFormSectionProps {
  index: number;
  title: string;
  sectionId: string;
  minHeight?: number;
  children: React.ReactNode;
}

const CreateAssignmentFormSection = ({
  index,
  title,
  sectionId,
  minHeight,
  children,
}: CreateAssignmentFormSectionProps) => (
  <S.Wrapper id={sectionId} minHeight={minHeight}>
    <S.Title>
      {index}.
      <S.TitleSpacer />
      {title}
    </S.Title>

    {children}
  </S.Wrapper>
);

export default CreateAssignmentFormSection;

import React from 'react';

const Sun = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 52" {...props}>
    <rect width="2.989" height="6.575" rx="1.494" transform="scale(-1 1) rotate(60 -10.885 4.559)" fill="#F6BE57" />
    <rect width="2.989" height="6.575" rx="1.494" transform="scale(-1 1) rotate(60 -50.224 -18.154)" fill="#F6BE57" />
    <rect x="22" width="2.989" height="6.575" rx="1.494" fill="#F6BE57" />
    <rect x="22" y="45.425" width="2.989" height="6.575" rx="1.494" fill="#F6BE57" />
    <rect x="45.033" y="12" width="2.989" height="6.575" rx="1.494" transform="rotate(60 45.033 12)" fill="#F6BE57" />
    <rect
      x="5.694"
      y="34.713"
      width="2.989"
      height="6.575"
      rx="1.494"
      transform="rotate(60 5.694 34.713)"
      fill="#F6BE57"
    />
    <rect width="30" height="30" rx="15" transform="matrix(1 0 0 -1 8 41)" fill="#F6BE57" />
  </svg>
);

export default Sun;

import React from 'react';

const Trash = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 21" {...props}>
    <path
      d="M1.818 7.588c0-1.128.915-2.043 2.043-2.043h9.554a2.04 2.04 0 012.04 2.04v11.372A2.043 2.043 0 0113.412 21H3.861a2.043 2.043 0 01-2.043-2.043V7.588z"
      fill="#4EAFAA"
    />
    <path
      d="M0 5.545c0-1.004.814-1.818 1.818-1.818h13.636a1.818 1.818 0 110 3.637H1.819A1.818 1.818 0 010 5.545zM6.364 9.182a.91.91 0 01.909.909v6.818a.91.91 0 01-1.818 0v-6.818a.91.91 0 01.909-.91zM10.91 9.182a.91.91 0 01.908.909v6.818a.91.91 0 01-1.818 0v-6.818a.91.91 0 01.91-.91z"
      fill="#245BCB"
    />
    <path d="M11.818 3.727a2.727 2.727 0 10-5.454 0" stroke="#4EAFAA" strokeWidth="2" />
  </svg>
);

export default Trash;

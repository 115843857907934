import React from 'react';

const Categories = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Four rounded squares divided as a 2x2 grid"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    {...props}>
    <rect x="9.773" y=".5" width="6.727" height="6.727" rx="2.5" stroke="currentColor" />
    <rect x="9.773" y="9.773" width="6.727" height="6.727" rx="2.5" stroke="currentColor" />
    <rect x=".5" y=".5" width="6.727" height="6.727" rx="2.5" stroke="currentColor" />
    <rect x=".5" y="9.773" width="6.727" height="6.727" rx="2.5" stroke="currentColor" />
  </svg>
);

export default Categories;

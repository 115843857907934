import React from 'react';

const MailVerified = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Mail icon with a circle checkmark"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 93 74"
    {...props}>
    <rect x="1.297" y=".428" width="90.632" height="72.769" rx="12.414" stroke="#333" strokeWidth=".856" />
    <path
      d="M2.67 7.14 16.867 26.75l19.307 26.675c4.477 6.184 13.69 6.184 18.166 0l30.82-42.58 4.488-5.655"
      stroke="#333"
      strokeWidth=".856"
      strokeLinecap="round"
    />
    <ellipse rx="12.042" ry="13.313" transform="matrix(1 0 0 -1 46.38 26.806)" fill="#00CFAA" />
    <path d="m44.88 30.73 6.731-7.443M44.88 30.73l-3.673-4.06" stroke="#fff" strokeWidth=".74" strokeLinecap="round" />
  </svg>
);

export default MailVerified;

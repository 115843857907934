import React from 'react';

const Calendar = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg role="img" aria-label="Calendar" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" {...props}>
    <rect x=".5" y="1.978" width="16" height="14.522" rx="3.5" stroke="currentColor" />
    <rect x="11.826" width="1.478" height="3.696" rx=".739" fill="currentColor" />
    <path fill="currentColor" d="M16.26 6.653v1.109H.736v-1.11z" />
    <rect x="3.697" width="1.478" height="3.696" rx=".739" fill="currentColor" />
  </svg>
);

export default Calendar;

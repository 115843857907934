import React from 'react';

const BurstDelete = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    viewBox="0 0 200 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="A trashcan inside of a circle centered above rays of light"
    {...props}>
    <circle cx="167.838" cy="55.9459" r="7.99228" fill="#F1F3F9" />
    <circle cx="24.672" cy="126.139" r="7.99228" fill="#F1F3F9" />
    <circle cx="48.9962" cy="24.672" r="7.99228" fill="#F1F3F9" />
    <circle cx="19.1894" cy="73.9384" r="4.16988" fill="#F1F3F9" />
    <circle cx="180" cy="92.4326" r="4.16988" fill="#F1F3F9" />
    <circle cx="96.6025" cy="23.3588" r="4.16988" fill="#F1F3F9" />
    <circle cx="37.9923" cy="152.992" r="4.9921" transform="rotate(-180 37.9923 152.992)" fill="#F1F3F9" />
    <circle cx="153.992" cy="27.9921" r="7.99228" transform="rotate(-180 153.992 27.9921)" fill="#F1F3F9" />
    <circle cx="159.992" cy="144.992" r="7.99228" transform="rotate(-180 159.992 144.992)" fill="#F1F3F9" />
    <circle cx="168.17" cy="114.17" r="4.16988" transform="rotate(-180 168.17 114.17)" fill="#F1F3F9" />
    <circle cx="19.1895" cy="88.378" r="4.16988" transform="rotate(-180 19.1895 88.378)" fill="#F1F3F9" />
    <circle cx="102.587" cy="157.452" r="4.16988" transform="rotate(-180 102.587 157.452)" fill="#F1F3F9" />
    <path d="M141.509 13.3996L120.179 64.5286L135.574 10.4248L141.509 13.3996Z" fill="#F1F3F9" />
    <path d="M184.17 57.8353L152.638 83.4928L184.17 65.6441V57.8353Z" fill="#F1F3F9" />
    <path d="M84.1952 21.5802L90.1306 58.3931L77.1469 22.5098L84.1952 21.5802Z" fill="#F1F3F9" />
    <path d="M20.5748 48.3532L49.51 77.5432L16.6797 51.328L20.5748 48.3532Z" fill="#F1F3F9" />
    <path
      d="M67.6873 169.018C69.4184 160.775 72.8808 144.104 72.8808 143.36L60.268 166.415L67.6873 169.018Z"
      fill="#F1F3F9"
    />
    <path d="M141.88 169.575L134.832 149.124L150.783 168.646L141.88 169.575Z" fill="#F1F3F9" />
    <path
      d="M161.317 91.0735C161.317 122.931 135.491 148.757 103.634 148.757C71.7759 148.757 45.9502 122.931 45.9502 91.0735C45.9502 59.2159 71.7759 33.3901 103.634 33.3901C135.491 33.3901 161.317 59.2159 161.317 91.0735Z"
      fill="#F1F3F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.6834 145.977C127.773 145.977 152.977 120.773 152.977 89.6834C152.977 58.5934 127.773 33.39 96.6834 33.39C65.5934 33.39 40.39 58.5934 40.39 89.6834C40.39 120.773 65.5934 145.977 96.6834 145.977ZM96.6834 147.367C128.541 147.367 154.367 121.541 154.367 89.6834C154.367 57.8257 128.541 32 96.6834 32C64.8257 32 39 57.8257 39 89.6834C39 121.541 64.8257 147.367 96.6834 147.367Z"
      fill="#AAB2C5"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="39" y="32" width="116" height="116">
      <circle cx="96.6834" cy="89.6834" r="57.6834" fill="#F1F3F9" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.6996 58.4185C90.1238 58.4185 89.6571 58.8852 89.6571 59.4609C89.6571 60.0367 90.1238 60.5034 90.6996 60.5034H103.017C103.593 60.5034 104.06 60.0367 104.06 59.4609C104.06 58.8852 103.593 58.4185 103.017 58.4185H90.6996ZM71.6016 65.5322C71.6016 64.9565 72.0683 64.4898 72.644 64.4898H121.282C121.858 64.4898 122.324 64.9565 122.324 65.5322C122.324 66.108 121.858 66.5747 121.282 66.5747H72.644C72.0683 66.5747 71.6016 66.108 71.6016 65.5322ZM114.051 123.186C114.266 123.014 114.413 122.758 114.44 122.462L119.032 70.995C119.083 70.4216 118.659 69.9168 118.086 69.8676C117.512 69.8184 117.006 70.2434 116.955 70.8168L112.44 121.416H80.9462L76.4317 70.8168C76.3805 70.2434 75.874 69.8184 75.3004 69.8676C74.7268 69.9168 74.3032 70.4216 74.3544 70.995L78.9464 122.462C78.9868 122.916 79.3118 123.276 79.7295 123.383C79.8736 123.458 80.0373 123.501 80.211 123.501H113.305C113.598 123.501 113.862 123.38 114.051 123.186ZM90.8267 112.543C90.2532 112.593 89.7475 112.169 89.6974 111.595L86.8528 79.082C86.8027 78.5085 87.2269 78.0029 87.8005 77.9527C88.374 77.9025 88.8797 78.3268 88.9299 78.9003L91.7744 111.413C91.8246 111.987 91.4003 112.493 90.8267 112.543ZM107.74 79.0818C107.79 78.5083 107.366 78.0026 106.793 77.9525C106.219 77.9023 105.713 78.3266 105.663 78.9001L102.819 111.413C102.769 111.987 103.193 112.492 103.766 112.543C104.34 112.593 104.846 112.168 104.896 111.595L107.74 79.0818Z"
        fill="#8F8E9B"
      />
    </g>
  </svg>
);

export default BurstDelete;

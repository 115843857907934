import React from 'react';

const Clever = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Clever icon, i.e. a blue square with rounded borders and a C letter at the middle"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1024 1024"
    {...props}>
    <defs>
      <path
        d="M357.01 0h309.98c102.802 0 154.19 0 209.528 17.493a217.48 217.48 0 0 1 129.989 129.989C1024 202.812 1024 254.217 1024 357.009v309.982c0 102.801 0 154.189-17.493 209.527a217.445 217.445 0 0 1-129.989 129.98C821.18 1024 769.792 1024 666.991 1024H357.009c-102.801 0-154.197 0-209.527-17.502a217.445 217.445 0 0 1-129.989-129.98C0 821.18 0 769.792 0 666.991V357.009c0-102.792 0-154.197 17.493-209.527A217.48 217.48 0 0 1 147.482 17.493C202.812 0 254.208 0 357.009 0"
        id="a"
      />
      <path
        d="M545.437 847.103c-191.332 0-333.437-147.17-333.437-333.2v-1.856C212 327.86 351.322 177 551.012 177c122.6 0 195.973 40.726 256.342 99.957l-91.02 104.593c-50.156-45.351-101.236-73.117-166.251-73.117-109.6 0-188.549 90.702-188.549 201.762v1.852c0 111.065 77.09 203.623 188.549 203.623 74.302 0 119.812-29.613 170.897-75.89L812 731.403c-66.873 71.265-141.176 115.7-266.563 115.7"
        id="d"
      />
      <linearGradient x1="9.706%" y1="8.762%" x2="82.898%" y2="83.189%" id="c">
        <stop stopColor="#4274F6" offset="0%" />
        <stop stopColor="#4274f6" offset="100%" />
      </linearGradient>
      <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="e">
        <feGaussianBlur stdDeviation="12.5" in="SourceAlpha" result="shadowBlurInner1" />
        <feOffset dx="15" dy="15" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          values="0 0 0 0 0.337254902 0 0 0 0 0.384313725 0 0 0 0 0.474509804 0 0 0 0.5 0"
          in="shadowInnerInner1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path fill="url(#c)" d="M0 0h1024v1024H0z" />
        <use fill="#FFF" xlinkHref="#d" />
      </g>
    </g>
  </svg>
);

export default Clever;

import React from 'react';

const Add = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" role="img" aria-label="Plus sign" {...props}>
    <rect x="7" width="1.5" height="15" rx=".75" fill="currentColor" />
    <rect x="15" y="7" width="1.5" height="15" rx=".75" transform="rotate(90 15 7)" fill="currentColor" />
  </svg>
);

export default Add;

const date = (event: React.ChangeEvent<HTMLInputElement>) => {
  event.target.maxLength = 10; // limit max characters by 10 (MM-DD-YYYY = 10 characters)

  let {value} = event.target;
  const alreadyMatchDateMask = value.match(/^(\d{2})-(\d{2})-(\d{4})$/);

  if (alreadyMatchDateMask) return event;

  value = value.replace(/\D/g, ''); // remove any non-numeric value
  value = value.replace(/(\d{2})(\d)/, '$1-$2'); // add dash between 2nd and 3rd numbers
  value = value.replace(/(\d{2})(\d)/, '$1-$2'); // add dash between 4th and 5th numbers
  event.target.value = value;

  return event;
};

export default {
  date,
};

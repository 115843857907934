type Environment = 'production' | 'staging' | 'development' | 'preview' | 'local';

export const TARGET_ENV = process.env.TARGET_ENV as Environment;
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
export const CLEVER_CLIENT_ID = process.env.CLEVER_CLIENT_ID;

export const JARVIS_API_HOST = {
  // Fix me: if make production
  production: 'https://jarvis-api.prod.riiidlabs.cloud',
  staging: 'https://jarvis-api.stg.riiidlabs.cloud',
  development: 'https://jarvis-api.dev.riiidlabs.cloud',
  preview: 'https://jarvis-api.dev.riiidlabs.cloud',
  //local: 'http://localhost:3001',
  local: 'https://jarvis-api.dev.riiidlabs.cloud',
}[TARGET_ENV];

export const GTM_ENV = {
  gtmId: 'GTM-MSJNGBX',
  ...{
    production: {
      preview: 'env-9',
      auth: 'c5tQEyd8EvQ5A3iUz5npVQ',
    },
    staging: {
      preview: 'env-7',
      auth: '9aB-ngScg0iHGoQ88Gv3UA',
    },
    development: {
      preview: 'env-8',
      auth: 'ZOEJedunYASf8G5CR5ZBWw',
    },
    preview: {
      preview: 'env-8',
      auth: 'ZOEJedunYASf8G5CR5ZBWw',
    },
    local: {
      preview: 'env-8',
      auth: 'ZOEJedunYASf8G5CR5ZBWw',
    },
  }[TARGET_ENV],
};

export const logBuildInformation = () => {
  console.log('BuildInfo', {
    version: process.env.APP_VERSION,
    time: new Date(process.env.BUILD_TIMESTAMP as string).toLocaleString(),
    apiHost: JARVIS_API_HOST,
  });
};

import React from 'react';

const KeyOutline = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Key with an outline style"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 63"
    {...props}>
    <circle
      r="13.5"
      transform="scale(-1 1) rotate(23.439 -72.881 -29.519)"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="m30.966 29.158 30.223 8.032-2.316 7.994M53.42 31.838l-2.317 7.995M48.158 29.76l-2.316 7.994"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export default KeyOutline;

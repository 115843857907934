import {
  User,
  signUp as _signUp,
  login as _login,
  findSelf,
  updateUserPassword,
  updateUser,
  refreshToken,
  sso,
} from '@jarvis/api-adapter';
import {useRouter} from 'next/router';
import React, {createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';

import useJarvisStorageValue from '@src/hooks/useJarvisStorageValue';
import {setUserToSentry} from '@src/utils/sentry';
import jarvisStorage, {AuthInfo} from '@src/utils/storage/JarvisStorage';

const DEFAULT_ROOT_URL = '/';
const HOME_URL_BASED_ON_USER_TYPE: Record<string, string> = {
  teacher: '/teachers/dashboard',
  student: '/students/dashboard',
};

// TODO: use userType based on backend type after they add it
const getHomeUrl = (userType?: string) => {
  if (!userType) return DEFAULT_ROOT_URL;
  return HOME_URL_BASED_ON_USER_TYPE[userType] ?? DEFAULT_ROOT_URL;
};

export interface AuthContextProps {
  authInfo?: AuthInfo;
  /**
   * 🚨: you can use this inside `<AuthenticationGuard />`
   */
  userInfo: User;
  isUserLoaded: boolean;
  homeUrl: string;
  logIn: (data: Parameters<typeof _login>[0]) => Promise<void>;
  logInWithClever: (data: Parameters<typeof sso>[0]) => Promise<void>;
  logOut: () => void;
  signUp: (data: Parameters<typeof _signUp>[0]) => Promise<void>;
  fetchUserInfo: () => Promise<User>;
  updateUserInfo: (data: {id: string} & Parameters<typeof updateUser>[1]) => Promise<void>;
  updatePassword: (data: Parameters<typeof updateUserPassword>[0]) => Promise<void>;
  redirectByUserType: (type?: string) => void;
  loadUserWhenAuthenticationGuardMounted: () => Promise<void>;
  loadUserWhenHomePageMounted: () => Promise<void>;
}

export const AuthContextDefaultValues = {
  authInfo: undefined,
  userInfo: {} as User,
  isUserLoaded: true,
  homeUrl: DEFAULT_ROOT_URL,
  logInWithClever: async () => {},
  logIn: async () => {},
  logOut: () => {},
  signUp: async () => {},
  forgotPassword: async () => {},
  fetchUserInfo: async () => ({} as User),
  updateUserInfo: async () => {},
  updatePassword: async () => {},
  redirectByUserType: () => {},
  loadUserWhenAuthenticationGuardMounted: async () => {},
  loadUserWhenHomePageMounted: async () => {},
};

export const AuthContext = createContext<AuthContextProps>(AuthContextDefaultValues);

export const AuthProvider = ({children}: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const [authInfo, setAuthInfo] = useJarvisStorageValue('authInfo');
  const [userInfo, setUserInfo] = useState<User | undefined>(undefined);

  const homeUrl = useMemo(() => getHomeUrl(userInfo?.type), [userInfo?.type]);

  const redirectByUserType = useCallback(
    (type?: string) => {
      const rootUrlByUserType = getHomeUrl(type);
      router.replace(rootUrlByUserType);
    },
    [router]
  );

  const logInWithClever = useCallback(
    async ({code}): Promise<void> => {
      const response = await sso({
        code: code as string,
      });
      setAuthInfo(response);
      redirectByUserType(response.userType);
    },
    [redirectByUserType, setAuthInfo]
  );

  const logIn = useCallback(
    async ({email, password}): Promise<void> => {
      const response = await _login({email, password});
      setAuthInfo(response);
      redirectByUserType(response.userType);
    },
    [redirectByUserType, setAuthInfo]
  );

  const logOut = useCallback(() => {
    setUserToSentry(null);
    setAuthInfo(undefined);
    setUserInfo(undefined);
    jarvisStorage.clear();
    router.replace('/signin').then(() => {
      // Fixme: If fix the bug where the old data remains, remove me
      router.reload();
    });
  }, [setAuthInfo, router]);

  const signUp = useCallback(async requestBody => {
    await _signUp(requestBody);
  }, []);

  const fetchUserInfo = useCallback(async () => {
    const user = await findSelf();
    setUserInfo(user);
    setUserToSentry({
      email: user.email,
      id: user.id,
    });
    return user;
  }, [setUserInfo]);

  const updateUserInfo: AuthContextProps['updateUserInfo'] = useCallback(
    async ({id, firstName, lastName, schoolName}) => {
      const updatedUser = await updateUser(id, {firstName, lastName, schoolName});
      setUserInfo(updatedUser);
    },
    [setUserInfo]
  );

  const updatePassword: AuthContextProps['updatePassword'] = useCallback(async ({newPassword, currentPassword}) => {
    await updateUserPassword({newPassword, currentPassword});
  }, []);

  const refreshAuthInfo = useCallback(async () => {
    if (!authInfo) throw Error('authInfo is not exist');
    const newAuthInfo = await refreshToken(authInfo);
    setAuthInfo(newAuthInfo);
    return newAuthInfo;
  }, [authInfo, setAuthInfo]);

  const loadUserWhenAuthenticationGuardMounted = useCallback(async () => {
    if (userInfo && authInfo) return;
    try {
      await fetchUserInfo();
    } catch {
      await refreshAuthInfo();
      await fetchUserInfo();
    }
  }, [authInfo, fetchUserInfo, refreshAuthInfo, userInfo]);

  const loadUserWhenHomePageMounted = useCallback(async () => {
    if (!authInfo) return;
    try {
      const user = await fetchUserInfo();
      redirectByUserType(user.type);
    } catch {
      await refreshAuthInfo();
      const user = await fetchUserInfo();
      redirectByUserType(user.type);
    }
  }, [authInfo, fetchUserInfo, redirectByUserType, refreshAuthInfo]);

  useEffect(() => {
    if (!authInfo) return;
    const sequence = setInterval(async () => {
      await refreshAuthInfo();
    }, 1000 * 60 * 30);
    return () => {
      clearInterval(sequence);
    };
  }, [authInfo, refreshAuthInfo]);

  return (
    <AuthContext.Provider
      value={{
        authInfo,
        userInfo: userInfo!,
        isUserLoaded: !!userInfo,
        homeUrl,
        logInWithClever,
        logIn,
        logOut,
        signUp,
        fetchUserInfo,
        updateUserInfo,
        updatePassword,
        redirectByUserType,
        loadUserWhenAuthenticationGuardMounted,
        loadUserWhenHomePageMounted,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

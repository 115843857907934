import React, {createContext, memo} from 'react';

import useJarvisStorageValue from '@src/hooks/useJarvisStorageValue';

export interface ClassroomIdContextProps {
  currentClassroomId: string | undefined;
  setCurrentClassroomId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ClassroomIdContextDefaultValues: ClassroomIdContextProps = {
  currentClassroomId: undefined,
  setCurrentClassroomId: async () => {},
};

export const ClassroomIdContext = createContext<ClassroomIdContextProps>(ClassroomIdContextDefaultValues);

export const ClassroomIdProvider = memo(({children}) => {
  const [currentClassroomId, setCurrentClassroomId] = useJarvisStorageValue('classroomId', undefined);

  return (
    <ClassroomIdContext.Provider
      value={{
        currentClassroomId,
        setCurrentClassroomId,
      }}>
      {children}
    </ClassroomIdContext.Provider>
  );
});

ClassroomIdProvider.displayName = 'ClassroomIdProvider';

import React from 'react';

const FolderOutline = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Closed folder with an outline style"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.898.285a2.507 2.507 0 012.507 2.507v1.119h6.33c1.112 0 2.014.901 2.014 2.014V15.7a2.014 2.014 0 01-2.014 2.014H2.014A2.014 2.014 0 010 15.701V2.792A2.507 2.507 0 012.507.285h4.391zM.986 3.911V2.792c0-.84.681-1.52 1.521-1.52h4.391c.84 0 1.521.68 1.521 1.52v1.119H.986zm14.748.986H.987V15.7c0 .568.46 1.028 1.028 1.028h13.72c.568 0 1.028-.46 1.028-1.028V5.925c0-.568-.46-1.028-1.027-1.028z"
      fill="currentColor"
    />
  </svg>
);

export default FolderOutline;

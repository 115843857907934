import React from 'react';

const BurstAlert = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    viewBox="0 0 174 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Danger symbol centered above rays of light"
    {...props}>
    <circle cx="156.709" cy="52.6804" r="7.98611" fill="#F1F3F9" />
    <circle cx="13.6541" cy="122.819" r="7.98611" fill="#F1F3F9" />
    <circle cx="37.9588" cy="21.4304" r="7.98611" fill="#F1F3F9" />
    <circle cx="18.1676" cy="60.6667" r="4.16667" fill="#F1F3F9" />
    <circle cx="168.862" cy="89.1393" r="4.16667" fill="#F1F3F9" />
    <circle cx="85.528" cy="30.111" r="4.16667" fill="#F1F3F9" />
    <circle cx="160.529" cy="132.195" r="4.16667" transform="rotate(-180 160.529 132.195)" fill="#F1F3F9" />
    <circle cx="4.97298" cy="89.8333" r="4.16667" transform="rotate(-180 4.97298 89.8333)" fill="#F1F3F9" />
    <circle cx="93.1673" cy="162.75" r="4.16667" transform="rotate(-180 93.1673 162.75)" fill="#F1F3F9" />
    <path d="M130.401 3.22248L109.087 54.312L124.47 0.25L130.401 3.22248Z" fill="#F1F3F9" />
    <path d="M173.029 47.6239L141.521 73.2616L173.029 55.4267V47.6239Z" fill="#F1F3F9" />
    <path d="M73.1314 11.3968L79.0622 48.1813L66.0885 12.3257L73.1314 11.3968Z" fill="#F1F3F9" />
    <path d="M9.56009 38.1491L38.473 67.3166L5.66797 41.1216L9.56009 38.1491Z" fill="#F1F3F9" />
    <path
      d="M56.6362 158.72C58.366 150.484 61.8257 133.826 61.8257 133.083L49.2226 156.12L56.6362 158.72Z"
      fill="#F1F3F9"
    />
    <path d="M130.772 159.278L123.729 138.842L139.668 158.349L130.772 159.278Z" fill="#F1F3F9" />
    <path
      d="M152.196 94.6945C152.196 126.528 126.39 152.333 94.5569 152.333C62.7238 152.333 36.918 126.528 36.918 94.6945C36.918 62.8615 62.7238 37.0557 94.5569 37.0557C126.39 37.0557 152.196 62.8615 152.196 94.6945Z"
      fill="#F1F3F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.6955 148.166C120.762 148.166 145.946 122.982 145.946 91.9162C145.946 60.8502 120.762 35.6662 89.6955 35.6662C58.6295 35.6662 33.4455 60.8502 33.4455 91.9162C33.4455 122.982 58.6295 148.166 89.6955 148.166ZM89.6955 149.555C121.529 149.555 147.334 123.749 147.334 91.9162C147.334 60.0832 121.529 34.2773 89.6955 34.2773C57.8624 34.2773 32.0566 60.0832 32.0566 91.9162C32.0566 123.749 57.8624 149.555 89.6955 149.555Z"
      fill="#AAB2C5"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="32" y="34" width="116" height="116">
      <circle cx="89.6955" cy="91.9162" r="57.6389" fill="#F1F3F9" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M89.1284 105.108C89.1436 105.496 89.4625 105.803 89.8507 105.803C90.2384 105.803 90.5571 105.497 90.573 105.11L91.8414 74.2228C91.8495 74.0256 91.6918 73.8613 91.4945 73.8613H88.2667C88.0696 73.8613 87.912 74.0252 87.9197 74.2221L89.1284 105.108ZM89.6957 117.611C91.239 117.611 92.4735 116.49 92.4735 115.013C92.4735 113.537 91.239 112.416 89.6957 112.416C88.1525 112.416 86.918 113.537 86.918 115.013C86.918 116.49 88.1525 117.611 89.6957 117.611Z"
        fill="#8F8E9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.578 111.361L97.515 64.4858C94.0402 58.4673 85.3532 58.4673 81.8784 64.4858L54.8151 111.361C51.3403 117.379 55.6838 124.902 62.6334 124.902H116.76C123.71 124.902 128.053 117.379 124.578 111.361ZM98.7178 63.7913C94.7084 56.8469 84.6849 56.8469 80.6756 63.7913L53.6123 110.666C49.6029 117.611 54.6146 126.291 62.6334 126.291H116.76C124.779 126.291 129.79 117.611 125.781 110.666L98.7178 63.7913Z"
        fill="#8F8E9B"
      />
    </g>
  </svg>
);

export default BurstAlert;

import {format, isToday, isYesterday} from 'date-fns';
export {
  isBefore,
  isAfter,
  isSameDay,
  isFuture,
  isDate as isValidDate,
  isPast,
  differenceInDays,
  addDays,
  formatDistanceStrict,
  endOfDay,
  isToday,
  isYesterday,
  compareAsc,
} from 'date-fns';

const SHORT_YEAR_LENGTH = 2;
const LONG_YEAR_LENGTH = 4;
const MIN_YEAR_VALUE = 1;

export const getCompleteFormattedDate = (date: number | Date) =>
  new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);

export const getCompleteShortDate = (date: number | Date) =>
  new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  }).format(date);

export const getCompleteTwoDigitLongYearDate = (date: number | Date) => format(date, 'P');

export const getShortNumericDate = (date: number | Date) =>
  new Intl.DateTimeFormat('en-US', {month: 'numeric', day: 'numeric'}).format(date);

export const getLongMonthNumericDayDate = (date: number | Date) =>
  new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'long',
  }).format(date);

export const getShortMonthNumericDayDate = (date: number | Date) =>
  new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
  }).format(date);

export const getGreetingMessage = () => {
  const currentHour = new Date().getHours();

  switch (true) {
    case currentHour < 12:
      return 'Good Morning';
    case currentHour < 18:
      return 'Good Afternoon';
    default:
      return 'Good Evening';
  }
};

export const isYearValid = (year: string) => {
  if (!year) return false;

  const hasValidLength = year.length === SHORT_YEAR_LENGTH || year.length === LONG_YEAR_LENGTH;
  if (!hasValidLength) return false;

  const numericYear = Number(year);
  const isValidYear = numericYear >= MIN_YEAR_VALUE && Number.isInteger(numericYear);
  return isValidYear;
};

export const formatFromInputDate = (value: string) => {
  const [month, day, year] = value.split('-');
  const validYear = isYearValid(year) && year;
  const date = new Date(`${month}/${day}/${validYear}`);

  return date;
};

export const formatDueDate = (value: string) => {
  const dueDate = formatFromInputDate(value);
  dueDate.setHours(23);
  dueDate.setMinutes(59);
  return dueDate;
};

export const getSuggestionDate = (date: Date) => {
  const time = new Intl.RelativeTimeFormat('en', {numeric: 'auto'});
  const today = time.format(0, 'day');
  const yesterday = time.format(-1, 'day');

  switch (true) {
    case isToday(date):
      return today;
    case isYesterday(date):
      return yesterday;
    default:
      return getShortMonthNumericDayDate(date);
  }
};

export type Node = {
  id: string;
  // root node has no parent.
  parent?: string | null;
  description: string;
  numberOfQuestions: number;
  // child node ids.
  children: string[];
};

export type Tree = {
  [id: string]: Node;
};

export const EMPTY_TREE: Tree = {root: {id: '', parent: null, description: '', numberOfQuestions: 0, children: []}};

const CheckedAssignment = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="A paper with a green circle containing a checkmark centered above two lines of greyed text"
    viewBox="0 0 112 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x="0.798828" width="110.403" height="131" rx="16.478" fill="#F8F8F9" />
    <rect x="18.9243" y="76.623" width="74.1509" height="6.81722" rx="3.40861" fill="#D6D6D6" />
    <rect x="18.9243" y="100.516" width="74.1509" height="6.81722" rx="3.40861" fill="#D6D6D6" />
    <circle cx="55.9984" cy="39.5472" r="21.5472" fill="#00CFAA" />
    <path d="M53.8452 46.0111L65.6962 34.1602" stroke="white" strokeWidth="2.15472" strokeLinecap="round" />
    <path d="M53.8452 46.011L47.3811 39.5469" stroke="white" strokeWidth="2.15472" strokeLinecap="round" />
  </svg>
);

export default CheckedAssignment;

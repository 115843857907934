import React from 'react';

const PersonCircle = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Person illustration inside a circle"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle r="60" transform="matrix(1 0 0 -1 60 60)" fill="#F0F6FF" />
    <path
      d="M75.8364 79.6706C73.3691 73.4481 67.2961 69.0479 60.1953 69.0479C53.0945 69.0479 47.0215 73.4481 44.5542 79.6706"
      stroke="#7E8EA6"
    />
    <circle cx="60.1955" cy="62.5563" r="23.1061" stroke="#7E8EA6" />
    <circle cx="60.1956" cy="57.8347" r="7.46706" stroke="#7E8EA6" />
  </svg>
);

export default PersonCircle;

import React from 'react';

const Remove = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <rect x="15" y="9" width="2" height="10" rx="1" transform="rotate(90 15 9)" fill="#000" />
    <rect x="15" y="9" width="2" height="10" rx="1" transform="rotate(90 15 9)" fill="currentColor" />
    <circle cx="10" cy="10" r="9" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default Remove;

import React from 'react';

const AttentionPaper = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Red circle icon with a paper in the middle"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    {...props}>
    <circle cx="15" cy="15" r="15" fill="#FFE2E9" />
    <rect x="8.5" y="7.5" width="13.143" height="16" rx="3.5" stroke="#FF6C90" />
    <rect
      x="19.166"
      y="12.666"
      width="1.214"
      height="8.188"
      rx=".607"
      transform="rotate(90 19.166 12.666)"
      fill="#FF6C90"
    />
    <rect
      x="19.166"
      y="17.524"
      width="1.214"
      height="8.188"
      rx=".607"
      transform="rotate(90 19.166 17.524)"
      fill="#FF6C90"
    />
  </svg>
);

export default AttentionPaper;

import {Node, Tree} from './types';

export const findRootNode = (tree: Tree) => {
  const rootNode = Object.values(tree).find(value => value.parent == null);
  if (!rootNode) throw Error('rootNode is not found');
  return rootNode;
};

export const findNode = (id: string, tree: Tree) => {
  const node = tree[id];
  if (!node) throw Error(`${id} node is not found`);
  return node;
};

export const getLastNodeIds = (id: string, tree: Tree) => {
  const result: string[] = [];

  const traverse = (id: string) => {
    const node = findNode(id, tree);
    if (node.children.length) {
      node.children.forEach(nodeId => traverse(nodeId));
    } else {
      result.push(id);
    }
  };

  traverse(id);

  return result;
};

export const getNodeExpanded = (id: string, expandedMap: Record<string, boolean>) => {
  return expandedMap[id] ?? false;
};

export const getNodeSelected = (
  id: string,
  selectedMap: Record<string, boolean>,
  tree: Tree
): 'all' | 'partial' | 'none' => {
  const lastNodeIds = getLastNodeIds(id, tree);
  const all = lastNodeIds.every(nodeId => selectedMap[nodeId] ?? false);
  const partial = lastNodeIds.some(nodeId => selectedMap[nodeId] ?? false);
  return all ? 'all' : partial ? 'partial' : 'none';
};

export const setNodeExpandedMap = (prevExpandedMap: Record<string, boolean>, id: string, value: boolean) => {
  return {
    ...prevExpandedMap,
    [id]: value,
  };
};

export const setNodeSelectedMap = (
  prevSelectedMap: Record<string, boolean>,
  id: string,
  value: boolean,
  tree: Tree
) => {
  const lastIds = getLastNodeIds(id, tree);

  return {
    ...prevSelectedMap,
    ...lastIds.reduce((current, nextId) => {
      return {
        ...current,
        [nextId]: value,
      };
    }, {}),
  };
};

export const findRootNodeId = (tree: Tree) => {
  const rootNode = findRootNode(tree);
  return rootNode.id;
};

export const getSelectedStandardNodes = (tree: Tree, selectedMap: Record<string, boolean>): Node[] => {
  const rootNodeId = findRootNodeId(tree);

  const selectedNodes: Node[] = [];

  const traverse = (id: string) => {
    const node = findNode(id, tree);

    if (id === rootNodeId) {
      node.children.forEach(nodeId => traverse(nodeId));
      return;
    }

    const nodeSelectedStatus = getNodeSelected(id, selectedMap, tree);

    if (nodeSelectedStatus === 'all') {
      selectedNodes.push({...node});
      return;
    } else if (nodeSelectedStatus === 'partial' && node.children.length) {
      node.children.forEach(nodeId => traverse(nodeId));
    }
  };

  traverse(rootNodeId);
  return selectedNodes;
};

export const getSelectedStandardIds = (tree: Tree, selectedMap: Record<string, boolean>): string[] => {
  const rootNodeId = findRootNodeId(tree);

  const selectedStandardIds: string[] = [];

  const traverse = (id: string) => {
    const node = findNode(id, tree);

    if (id === rootNodeId) {
      node.children.forEach(nodeId => traverse(nodeId));
      return;
    }

    const nodeSelectedStatus = getNodeSelected(id, selectedMap, tree);

    if (nodeSelectedStatus !== 'none') {
      if (node.children.length) {
        node.children.forEach(nodeId => traverse(nodeId));
      } else {
        selectedStandardIds.push(id);
      }
    }
  };

  traverse(rootNodeId);
  return selectedStandardIds;
};

export const getNewSelectedMap = (
  prevSelectedMap: Record<string, boolean>,
  id: string,
  value: boolean,
  tree: Tree
): Record<string, boolean> => {
  const lastIds = getLastNodeIds(id, tree);

  return {
    ...prevSelectedMap,
    ...lastIds.reduce((current, nextId) => {
      return {
        ...current,
        [nextId]: value,
      };
    }, {}),
  };
};

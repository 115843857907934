import React from 'react';

import {PerformanceMeterVariant} from '@src/components/atoms/PerformanceMeter';

export interface MeterAboveAverageProps extends React.SVGAttributes<HTMLOrSVGElement> {
  variant?: PerformanceMeterVariant;
}

const MeterAboveAverage = ({variant = 'dashboard', ...props}: MeterAboveAverageProps) => {
  switch (variant) {
    case 'student':
      return (
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 53"
          role="img"
          aria-label="Triangle divided into 3 parts, with the last and biggest one painted in green"
          {...props}>
          <path
            opacity=".4"
            d="M60.31 51.385H37.413v-26.29l22.898-11.909v38.2zm6.103 0h16.36a4.577 4.577 0 004.578-4.577V6.663c0-3.435-3.642-5.646-6.689-4.06l-14.249 7.41v41.372zM31.311 28.27v23.116H5.58c-4.79 0-6.361-6.427-2.111-8.637l27.84-14.48z"
            fill="#D6D6D6"
          />
          <path
            d="M82.784 52.148H70.58a4.577 4.577 0 01-4.577-4.577v-36.38a4.577 4.577 0 013.32-4.401L81.527.179c2.924-.836 5.834 1.36 5.834 4.4v42.992a4.577 4.577 0 01-4.577 4.577z"
            fill="#00CFAA"
          />
        </svg>
      );
    default:
      return (
        <svg
          viewBox="0 0 56 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Triangle divided into 3 parts, with the last and biggest one painted in green"
          {...props}>
          <path
            opacity=".4"
            d="M35.993 5.382l-15 3.65V19.5h15V5.382zm4 14.118h11a3 3 0 003-3V4.818a3 3 0 00-3.71-2.915l-10.29 2.505V19.5zm-23-9.493V19.5H3.002c-3.549 0-4.158-5.076-.71-5.915l14.701-3.579z"
            fill="#D6D6D6"
          />
          <path
            d="M51 20h-8a3 3 0 01-3-3V6.263a3 3 0 012.176-2.885l8-2.285A3 3 0 0154 3.977V17a3 3 0 01-3 3z"
            fill="#00CFAA"
          />
        </svg>
      );
  }
};

export default MeterAboveAverage;

import React from 'react';

const Search = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Magnifying glass"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 20"
    {...props}>
    <circle cx="7.462" cy="8.321" r="6.831" transform="rotate(.703 7.462 8.321)" stroke="currentColor" />
    <path d="M12.19 13.875l4.1 4.963" stroke="currentColor" strokeLinecap="round" />
  </svg>
);

export default Search;

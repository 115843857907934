import {Student, ContentGradeLevel} from '@jarvis/api-adapter';

import {Node, Tree, EMPTY_TREE} from '@src/components/TreeView/types';
import {getNewSelectedMap, getSelectedStandardNodes, getSelectedStandardIds} from '@src/components/TreeView/utils';
import {
  AssignmentDetailsFormData,
  initialAssignmentDetailsFormData,
} from '@src/components/organisms/CreateAssignmentDetailsSection';

export interface CreateAssignmentReducerState {
  students: Student[];
  selectedStudentIds: string[];
  assignmentDetailsFormData: AssignmentDetailsFormData;
  tree: Tree;
  gradeLevel: ContentGradeLevel | undefined;
  selectedStandardNodes: Node[];
  selectedStandardIds: string[];
  selectedMap: Record<string, boolean>;
}

export const createAssignmentInitialState: CreateAssignmentReducerState = {
  students: [],
  selectedStudentIds: [],
  assignmentDetailsFormData: initialAssignmentDetailsFormData,
  gradeLevel: undefined,
  tree: EMPTY_TREE,
  selectedStandardNodes: [],
  selectedStandardIds: [],
  selectedMap: {},
};

export enum ActionType {
  SET_STUDENTS = 'SET_STUDENTS',
  SELECT_STUDENT = 'SELECT_STUDENT',
  DESELECT_STUDENT = 'DESELECT_STUDENT',
  SELECT_ALL_STUDENTS = 'SELECT_ALL_STUDENTS',
  DESELECT_ALL_STUDENTS = 'DESELECT_ALL_STUDENTS',
  UPDATE_ASSIGNMENT_INPUT = 'UPDATE_ASSIGNMENT_INPUT',
  SET_TREE_AND_GRADE_LEVEL = 'SET_TREE_AND_GRADE_LEVEL',
  SET_SELECTED_STANDARDS = 'SET_SELECTED_STANDARDS',
  DESELECT_STANDARD = 'DESELECT_STANDARD',
  CLEAR_SELECTED_STANDARDS = 'CLEAR_SELECTED_STANDARDS',
  SET_STANDARD_SELECTION = 'SET_STANDARD_SELECTION',
  RESET_STATE = 'RESET_STATE',
}

export type UpdateAssignmentInputPayload = {
  value: string;
  field: string;
};

export type SetStandardSelectionPayload = {
  id: string | number;
  value: boolean;
};

export type SetTreeAndGradeLevelPayload = {
  tree: Tree;
  gradeLevel: ContentGradeLevel;
};

export type CreateAssignmentReducerAction =
  | {type: ActionType.SET_STUDENTS; payload: Student[]}
  | {type: ActionType.SELECT_STUDENT; payload: string}
  | {type: ActionType.DESELECT_STUDENT; payload: string}
  | {type: ActionType.SELECT_ALL_STUDENTS}
  | {type: ActionType.DESELECT_ALL_STUDENTS}
  | {type: ActionType.UPDATE_ASSIGNMENT_INPUT; payload: UpdateAssignmentInputPayload}
  | {type: ActionType.SET_TREE_AND_GRADE_LEVEL; payload: SetTreeAndGradeLevelPayload}
  | {type: ActionType.SET_SELECTED_STANDARDS; payload: Node[]}
  | {type: ActionType.CLEAR_SELECTED_STANDARDS}
  | {type: ActionType.DESELECT_STANDARD; payload: string | number}
  | {type: ActionType.SET_STANDARD_SELECTION; payload: SetStandardSelectionPayload}
  | {type: ActionType.RESET_STATE};

export const CreateAssignmentReducer = (state: CreateAssignmentReducerState, action: CreateAssignmentReducerAction) => {
  switch (action.type) {
    case ActionType.SET_STUDENTS: {
      return {...state, students: action.payload};
    }

    case ActionType.SELECT_STUDENT: {
      const newSelectedStudents = [...state.selectedStudentIds, action.payload];
      return {...state, selectedStudentIds: newSelectedStudents};
    }

    case ActionType.DESELECT_STUDENT: {
      const newSelectedStudents = state.selectedStudentIds.filter(studentId => studentId !== action.payload);
      return {...state, selectedStudentIds: newSelectedStudents};
    }

    case ActionType.SELECT_ALL_STUDENTS: {
      const allStudentIds = state.students.map(student => student.id);
      return {...state, selectedStudentIds: allStudentIds};
    }

    case ActionType.DESELECT_ALL_STUDENTS:
      return {...state, selectedStudentIds: []};

    case ActionType.UPDATE_ASSIGNMENT_INPUT: {
      const newAssignmentFormDetails = {
        ...state.assignmentDetailsFormData,
        [action.payload.field]: action.payload.value,
      };
      return {...state, assignmentDetailsFormData: newAssignmentFormDetails};
    }

    case ActionType.SET_TREE_AND_GRADE_LEVEL:
      return {
        ...state,
        tree: action.payload.tree,
        gradeLevel: action.payload.gradeLevel,
        selectedStandardNodes: [],
        selectedStandardIds: [],
        selectedMap: {},
      };

    case ActionType.SET_SELECTED_STANDARDS:
      return {...state, selectedStandardNodes: action.payload};

    case ActionType.CLEAR_SELECTED_STANDARDS:
      return {...state, selectedStandardNodes: [], selectedStandardIds: [], selectedMap: {}};

    case ActionType.DESELECT_STANDARD: {
      const newSelectedStandards = state.selectedStandardNodes.filter(standard => standard.id !== action.payload);
      const newSelectedMap = getNewSelectedMap(state.selectedMap, action.payload as string, false, state.tree);
      const newSelectedStandardIds = getSelectedStandardIds(state.tree, newSelectedMap);
      return {
        ...state,
        selectedStandardNodes: newSelectedStandards,
        selectedStandardIds: newSelectedStandardIds,
        selectedMap: newSelectedMap,
      };
    }

    case ActionType.SET_STANDARD_SELECTION: {
      const newSelectedMap = getNewSelectedMap(
        state.selectedMap,
        action.payload.id as string,
        action.payload.value,
        state.tree
      );
      const newSelectedStandardNodes = getSelectedStandardNodes(state.tree, newSelectedMap);
      const newSelectedStandardIds = getSelectedStandardIds(state.tree, newSelectedMap);
      return {
        ...state,
        selectedStandardNodes: newSelectedStandardNodes,
        selectedStandardIds: newSelectedStandardIds,
        selectedMap: newSelectedMap,
      };
    }

    case ActionType.RESET_STATE: {
      return createAssignmentInitialState;
    }

    default:
      throw new Error();
  }
};

export default CreateAssignmentReducer;

import React from 'react';

const LowPerformance = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg role="img" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.2481 5.75C13.2481 5.05964 12.6885 4.5 11.9981 4.5C11.3078 4.5 10.7481 5.05964 10.7481 5.75L10.7481 15.1456L7.59746 11.8864C7.11764 11.3901 6.33969 11.3901 5.85987 11.8864C5.38004 12.3828 5.38004 13.1875 5.85987 13.6839L11.1308 19.1363C11.2604 19.2704 11.4117 19.3682 11.5731 19.4299C11.7851 19.5109 12.0144 19.5294 12.2343 19.4853C12.4666 19.4388 12.6885 19.3225 12.8684 19.1363L18.1394 13.6839C18.6192 13.1875 18.6192 12.3828 18.1394 11.8864C17.6596 11.3901 16.8816 11.3901 16.4018 11.8864L13.2481 15.1487L13.2481 5.75Z"
      fill="#E62B76"
    />
  </svg>
);

export default LowPerformance;

import {css} from '@emotion/react';
import styled from '@emotion/styled';

interface WrapperProps {
  minHeight?: number;
}

export const Wrapper = styled.section<WrapperProps>`
  ${({theme, minHeight}) => css`
    background-color: ${theme.colors.lightBg};
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    padding: 40px 60px 52px 30px;
    min-height: ${minHeight ? `${minHeight}px` : 'unset'};
  `}
`;

export const Title = styled.h2`
  ${({theme}) => css`
    display: flex;
    font-size: 26px;
    font-weight: ${theme.font.weight.normal};
  `}
`;

export const TitleSpacer = styled.span`
  width: 10px;
  display: block;
`;

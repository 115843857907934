import React, {createContext, memo, useCallback, useReducer} from 'react';
import {Student, ContentGradeLevel} from '@jarvis/api-adapter';

import {Node, Tree} from '@src/components/TreeView/types';
import {
  CreateAssignmentReducer,
  CreateAssignmentReducerState,
  ActionType as Action,
  createAssignmentInitialState,
} from '@src/reducers/CreateAssignmentReducer';

export interface CreateAssignmentContextProps {
  createAssignmentState: CreateAssignmentReducerState;
  setStudents: (students: Student[]) => void;
  selectStudent: (id: string) => void;
  deselectStudent: (id: string) => void;
  selectAllStudents: () => void;
  deselectAllStudents: () => void;
  updateAssignmentInput: (value: string, field: string) => void;
  setTreeAndGradeLevel: (tree: Tree, level: ContentGradeLevel) => void;
  setSelectedStandards: (selectedStandards: Node[]) => void;
  clearSelectedStandards: () => void;
  deselectStandard: (id: string | number) => void;
  setStandardSelectionState: (id: string | number, value: boolean) => void;
  resetState: () => void;
}

export const CreateAssignmentContextDefaultValues: CreateAssignmentContextProps = {
  createAssignmentState: createAssignmentInitialState,
  setStudents: () => {},
  selectStudent: () => {},
  deselectStudent: () => {},
  selectAllStudents: () => {},
  deselectAllStudents: () => {},
  updateAssignmentInput: () => {},
  setTreeAndGradeLevel: () => {},
  setSelectedStandards: () => {},
  clearSelectedStandards: () => {},
  deselectStandard: () => {},
  setStandardSelectionState: () => {},
  resetState: () => {},
};

export const CreateAssignmentContext = createContext<CreateAssignmentContextProps>(
  CreateAssignmentContextDefaultValues
);

export const CreateAssignmentProvider = memo(({children}) => {
  const [state, dispatch] = useReducer(CreateAssignmentReducer, createAssignmentInitialState);

  const setStudents = useCallback((students: Student[]) => {
    dispatch({
      type: Action.SET_STUDENTS,
      payload: students,
    });
  }, []);

  const selectStudent = (studentId: string) => {
    dispatch({
      type: Action.SELECT_STUDENT,
      payload: studentId,
    });
  };

  const deselectStudent = (studentId: string) => {
    dispatch({
      type: Action.DESELECT_STUDENT,
      payload: studentId,
    });
  };

  const selectAllStudents = () => {
    dispatch({
      type: Action.SELECT_ALL_STUDENTS,
    });
  };

  const deselectAllStudents = () => {
    dispatch({
      type: Action.DESELECT_ALL_STUDENTS,
    });
  };

  const updateAssignmentInput = (value: string, field: string) => {
    dispatch({
      type: Action.UPDATE_ASSIGNMENT_INPUT,
      payload: {value, field},
    });
  };

  const setTreeAndGradeLevel = (tree: Tree, gradeLevel: ContentGradeLevel) => {
    dispatch({
      type: Action.SET_TREE_AND_GRADE_LEVEL,
      payload: {tree, gradeLevel},
    });
  };

  const setSelectedStandards = (selectedStandards: Node[]) => {
    dispatch({
      type: Action.SET_SELECTED_STANDARDS,
      payload: selectedStandards,
    });
  };

  const setStandardSelectionState = (id: string | number, value: boolean) => {
    dispatch({
      type: Action.SET_STANDARD_SELECTION,
      payload: {id, value},
    });
  };

  const clearSelectedStandards = () => {
    dispatch({
      type: Action.CLEAR_SELECTED_STANDARDS,
    });
  };

  const deselectStandard = (id: string | number) => {
    dispatch({
      type: Action.DESELECT_STANDARD,
      payload: id,
    });
  };

  const resetState = () => {
    dispatch({
      type: Action.RESET_STATE,
    });
  };

  return (
    <CreateAssignmentContext.Provider
      value={{
        createAssignmentState: state,
        setStudents,
        selectStudent,
        deselectStudent,
        selectAllStudents,
        deselectAllStudents,
        updateAssignmentInput,
        setTreeAndGradeLevel,
        setSelectedStandards,
        clearSelectedStandards,
        deselectStandard,
        setStandardSelectionState,
        resetState,
      }}>
      {children}
    </CreateAssignmentContext.Provider>
  );
});

CreateAssignmentProvider.displayName = 'CreateAssignmentProvider';

import React from 'react';

import {PerformanceMeterVariant} from '@src/components/atoms/PerformanceMeter';

export interface MeterEmptyProps extends React.SVGAttributes<HTMLOrSVGElement> {
  variant?: PerformanceMeterVariant;
}

const MeterEmpty = ({variant = 'dashboard', ...props}: MeterEmptyProps) => {
  switch (variant) {
    case 'student':
      return (
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 50"
          role="img"
          aria-label="Triangle divided into 3 parts, with all the parts painted in gray"
          {...props}>
          <path
            opacity=".4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.31 49.306H37.413v-26.29l22.898-11.91v38.2zm6.103 0h16.36a4.577 4.577 0 004.578-4.577V4.583c0-3.434-3.642-5.645-6.689-4.06l-14.249 7.41v41.373zM31.311 26.189v23.117H5.58c-4.79 0-6.361-6.427-2.111-8.638l27.84-14.48z"
            fill="#D6D6D6"
          />
        </svg>
      );
    default:
      return (
        <svg
          viewBox="0 0 56 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Triangle divided into 3 parts, with all the parts painted in gray"
          {...props}>
          <path
            opacity=".4"
            d="M35.993 4.382l-15 3.65V18.5h15V4.382zm4 14.118h11a3 3 0 003-3V3.818a3 3 0 00-3.71-2.915l-10.29 2.505V18.5zm-23-9.493V18.5H3.002c-3.549 0-4.158-5.076-.71-5.915l14.701-3.578z"
            fill="#D6D6D6"
          />
        </svg>
      );
  }
};

export default MeterEmpty;

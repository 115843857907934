import React from 'react';

import {PerformanceMeterVariant} from '@src/components/atoms/PerformanceMeter';

export interface MeterBelowAverageProps extends React.SVGAttributes<HTMLOrSVGElement> {
  variant?: PerformanceMeterVariant;
}

const MeterBelowAverage = ({variant = 'dashboard', ...props}: MeterBelowAverageProps) => {
  switch (variant) {
    case 'student':
      return (
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 51"
          role="img"
          aria-label="Triangle divided into 3 parts, with the first and smallest one painted in red"
          {...props}>
          <path
            opacity=".4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.31 49.306H37.413v-26.29l22.898-11.91v38.2zm6.103 0h16.36a4.577 4.577 0 004.578-4.577V4.583c0-3.434-3.642-5.645-6.689-4.06l-14.249 7.41v41.373zM31.311 26.189v23.117H5.58c-4.79 0-6.361-6.427-2.111-8.638l27.84-14.48z"
            fill="#D6D6D6"
          />
          <path
            d="M27.833 50.455H5.206c-6.156 0-7.208-9.455-1.233-12.263L28.746 26a3.47 3.47 0 014.293 3.372v15.877a5.206 5.206 0 01-5.206 5.206z"
            fill="#FF6C90"
          />
        </svg>
      );
    default:
      return (
        <svg
          viewBox="0 0 58 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Triangle divided into 3 parts, with the first and smallest one painted in red"
          {...props}>
          <path
            opacity=".4"
            d="M37.993 3.565l-15 3.651v10.467h15V3.565zm4 14.118h11a3 3 0 003-3V3.002a3 3 0 00-3.71-2.914L41.994 2.59v15.092zm-23-9.493v9.493H5.002c-3.549 0-4.158-5.075-.71-5.914L18.993 8.19z"
            fill="#D6D6D6"
          />
          <path
            d="M17 18H3.96c-3.547 0-4.157-5.074-.71-5.915l14.276-3.482A2 2 0 0120 10.547V15a3 3 0 01-3 3z"
            fill="#FF6C90"
          />
        </svg>
      );
  }
};

export default MeterBelowAverage;

import React from 'react';

const Logout = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="A door with a arrow pointing to the right side."
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 15"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.908.035A2.372 2.372 0 0 0 .044 1.926c-.058.324-.059 10.815 0 11.142.087.488.327.926.7 1.28.36.343.777.55 1.252.624.16.025 1.162.032 3.711.026l3.492-.01.143-.088a.759.759 0 0 0 .233-.232c.078-.127.088-.17.088-.368 0-.2-.01-.241-.088-.368a.759.759 0 0 0-.233-.233l-.143-.088-3.55-.015-3.55-.015-.153-.08a.979.979 0 0 1-.445-.46l-.077-.163V2.117l.077-.164a.979.979 0 0 1 .445-.46l.153-.08 3.55-.014 3.55-.015.143-.089a.758.758 0 0 0 .233-.232c.078-.127.088-.17.088-.368 0-.199-.01-.241-.088-.368a.758.758 0 0 0-.233-.232L9.2.005 5.649.002C2.815-.003 2.06.004 1.909.035ZM8.723 3.52c-.257.12-.394.347-.393.652.001.276.044.33 1.224 1.513l1.104 1.108h-2.81c-2.12 0-2.84.008-2.931.035-.252.075-.487.397-.487.668s.235.594.487.668c.091.027.81.036 2.93.036h2.81L9.557 9.308c-.606.609-1.126 1.153-1.156 1.21-.039.072-.054.166-.055.324 0 .197.01.238.088.366.164.265.486.39.797.306.13-.035.262-.16 1.924-1.815C12.196 8.66 12.964 7.874 13 7.805a.76.76 0 0 0 0-.616c-.037-.068-.808-.86-1.847-1.896-1.925-1.92-1.833-1.842-2.15-1.842a.763.763 0 0 0-.28.07Z"
      fill="currentColor"
    />
  </svg>
);

export default Logout;

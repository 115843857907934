import React from 'react';

const PadlockConfirm = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Padlock with an outline style and checkmark"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 72"
    {...props}>
    <rect
      x="0.820437"
      y="28.266"
      width="54.3596"
      height="42.8469"
      rx="10.8701"
      stroke="#00CFAA"
      strokeWidth="1.28541"
    />
    <path
      d="M46.2291 28.5827V19.2285C46.2291 9.1612 38.0679 1 28.0005 1V1C17.9332 1 9.77197 9.1612 9.77197 19.2285V28.5827"
      stroke="#00CFAA"
      strokeWidth="1.28541"
    />
    <path d="M24.5884 56.6049L39.8217 41.3716" stroke="#00CFAA" strokeWidth="1.28541" strokeLinecap="round" />
    <path d="M24.5884 56.605L16.2793 48.2959" stroke="#00CFAA" strokeWidth="1.28541" strokeLinecap="round" />
  </svg>
);

export default PadlockConfirm;

import React from 'react';

const TrashOutline = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Trashcan with an outline style"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 16"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.757 0a.5.5 0 000 1h2.485a.5.5 0 100-1H5.757zM.864 3.5a.5.5 0 01.5-.5h11.272a.5.5 0 010 1h-.21l-1.027 11.502a.5.5 0 01-.186.347.498.498 0 01-.358.15H3.343a.497.497 0 01-.273-.08.502.502 0 01-.302-.417L1.74 4h-.377a.5.5 0 01-.5-.5zM3.727 15L2.745 4h8.677l-.982 11H3.727z"
      fill="currentColor"
    />
  </svg>
);

export default TrashOutline;

import React from 'react';

const Gear = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Hexagonal nut"
    {...props}>
    <path
      d="M12.702 16.5C13.2379 16.5 13.7331 16.2141 14.001 15.75L17.4652 9.75001C17.7332 9.2859 17.7332 8.7141 17.4652 8.24999L14.001 2.24999C13.7331 1.78589 13.2379 1.5 12.702 1.5H5.77359C5.2377 1.5 4.74251 1.78589 4.47456 2.24999L1.01038 8.24999C0.742424 8.7141 0.742424 9.2859 1.01038 9.75001L4.47456 15.75C4.74251 16.2141 5.2377 16.5 5.77359 16.5H12.702Z"
      stroke="currentColor"
    />
    <circle r="2.66484" transform="matrix(1 0 0 -1 9.31889 8.91231)" stroke="currentColor" />
  </svg>
);

export default Gear;

import React from 'react';

const AlertCircleFull = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Red circle with exclamation mark inside."
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 91"
    {...props}>
    <circle cx="46" cy="45.273" r="45.273" fill="#FF6C90" />
    <path
      d="M44.502 54.64h2.16l.48-25.968H44.07l.432 25.968Zm.96 9.6c1.2 0 2.16-.912 2.16-2.112 0-1.2-.96-2.112-2.16-2.112-1.2 0-2.16.912-2.16 2.112 0 1.2.96 2.112 2.16 2.112Z"
      fill="#fff"
    />
  </svg>
);

export default AlertCircleFull;

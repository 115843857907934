import React from 'react';

const BurstAssignment = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 313 275"
    role="img"
    aria-label="Assignment symbol centered above bubbles"
    {...props}>
    <circle cx="282.479" cy="73.425" r="14.936" fill="#F1F3F9" />
    <circle cx="14.936" cy="204.6" r="14.936" fill="#F1F3F9" />
    <circle cx="60.392" cy="14.981" r="14.936" fill="#F1F3F9" />
    <circle cx="23.378" cy="88.361" r="7.793" fill="#F1F3F9" />
    <circle cx="305.207" cy="141.61" r="7.793" fill="#F1F3F9" />
    <circle cx="149.357" cy="31.216" r="7.793" fill="#F1F3F9" />
    <path
      d="M273.048 167.144c0 59.534-48.262 107.796-107.796 107.796-59.535 0-107.797-48.262-107.797-107.796 0-59.535 48.262-107.797 107.797-107.797 59.534 0 107.796 48.262 107.796 107.797Z"
      fill="#F1F3F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.676 271.485c58.1 0 105.199-47.099 105.199-105.199S214.776 61.087 156.676 61.087 51.477 108.186 51.477 166.286s47.1 105.199 105.199 105.199Zm0 2.598c59.535 0 107.797-48.262 107.797-107.797 0-59.534-48.262-107.797-107.797-107.797-59.534 0-107.796 48.263-107.796 107.797 0 59.535 48.262 107.797 107.796 107.797Z"
      fill="#AAB2C5"
    />
    <mask id="a" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="48" y="58" width="217" height="217">
      <circle cx="156.676" cy="166.286" r="107.797" fill="#F1F3F9" />
    </mask>
    <g mask="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#8F8E9B">
      <path d="M85.245 131.22c0-15.063 12.21-27.274 27.274-27.274h87.016c15.063 0 27.274 12.211 27.274 27.274v132.151c0 15.063-12.211 27.274-27.274 27.274h-87.016c-15.063 0-27.274-12.211-27.274-27.274V131.22Zm27.274-24.677c-13.629 0-24.677 11.048-24.677 24.677v132.151c0 13.628 11.048 24.676 24.677 24.676h87.016c13.629 0 24.677-11.048 24.677-24.676V131.22c0-13.629-11.048-24.677-24.677-24.677h-87.016Z" />
      <path d="M189.015 139.959a1.296 1.296 0 0 1 0 1.836l-41.117 41.117a1.296 1.296 0 0 1-1.836 0 1.296 1.296 0 0 1 0-1.836l41.117-41.117a1.296 1.296 0 0 1 1.836 0Z" />
      <path d="M123.634 158.648a1.3 1.3 0 0 0 0 1.837l22.428 22.428a1.298 1.298 0 1 0 1.836-1.837l-22.427-22.428a1.3 1.3 0 0 0-1.837 0ZM105.548 209.322c0-.717.582-1.298 1.299-1.298h98.361a1.298 1.298 0 1 1 0 2.597h-98.361a1.299 1.299 0 0 1-1.299-1.299ZM105.548 229.515c0-.718.582-1.299 1.299-1.299h98.361a1.298 1.298 0 1 1 0 2.597h-98.361a1.299 1.299 0 0 1-1.299-1.298ZM130.491 249.706a1.3 1.3 0 0 1 1.299-1.299h47.882a1.299 1.299 0 0 1 0 2.598H131.79a1.3 1.3 0 0 1-1.299-1.299Z" />
    </g>
  </svg>
);

export default BurstAssignment;

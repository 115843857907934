import {StorageAdapter} from '@src/utils/storage/StorageAdapter';

// Information we need for call to get new accessToken.
export interface AuthInfo {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export interface JarvisStorageValues {
  classroomId: string;
  authInfo: AuthInfo;
}

const jarvisStorage = new StorageAdapter<JarvisStorageValues>(typeof window !== 'undefined' ? localStorage : undefined);

export default jarvisStorage;

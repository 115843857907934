import React from 'react';

const BurstStar = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    viewBox="0 0 200 212"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="5 point star centered above rays of light"
    {...props}>
    <g clipPath="url(#clip0)">
      <ellipse rx="6.63721" ry="6.41597" transform="matrix(-1 0 0 1 60.4341 78.0366)" fill="#F1F3F9" />
      <circle r="6.41597" transform="matrix(-1 0 0 1 188.089 124.497)" fill="#F1F3F9" />
      <circle r="6.41597" transform="matrix(-1 0 0 1 47.3809 134.232)" fill="#F1F3F9" />
      <circle r="6.41597" transform="matrix(-1 0 0 1 157.56 27.5942)" fill="#F1F3F9" />
      <circle r="6.41597" transform="matrix(-1 0 0 1 60.2129 27.5942)" fill="#F1F3F9" />
      <ellipse rx="3.53984" ry="3.3186" transform="matrix(-1 0 0 1 151.143 71.3997)" fill="#F1F3F9" />
      <ellipse rx="3.53984" ry="3.3186" transform="matrix(-1 0 0 1 109.55 176.268)" fill="#F1F3F9" />
      <circle r="3.3186" transform="matrix(-1 0 0 1 4.01831 94.4089)" fill="#F1F3F9" />
      <circle r="3.3186" transform="matrix(-1 0 0 1 104.019 49.2756)" fill="#F1F3F9" />
      <circle r="3.3186" transform="matrix(-1 0 0 1 154.462 140.427)" fill="#F1F3F9" />
      <path d="M149.594 15.4266L124.151 76.2676L142.514 11.8867L149.594 15.4266Z" fill="#F1F3F9" />
      <path d="M200.479 68.303L162.868 98.8342L200.479 77.5951V68.303Z" fill="#F1F3F9" />
      <path d="M81.2307 25.1611L88.3104 68.9667L72.8236 26.2673L81.2307 25.1611Z" fill="#F1F3F9" />
      <path d="M5.34527 57.0197L39.8588 91.7545L0.699219 60.5596L5.34527 57.0197Z" fill="#F1F3F9" />
      <path
        d="M61.5403 200.605C63.6052 190.796 67.735 170.958 67.735 170.074L52.6907 197.507L61.5403 200.605Z"
        fill="#F1F3F9"
      />
      <path d="M150.036 201.268L141.629 176.932L160.656 200.162L150.036 201.268Z" fill="#F1F3F9" />
      <path
        opacity="0.1"
        d="M101.068 69.0042C102.392 64.9276 108.16 64.9276 109.484 69.0042L118.042 95.3411C118.634 97.1642 120.333 98.3985 122.25 98.3985H149.942C154.228 98.3985 156.011 103.884 152.543 106.403L130.139 122.68C128.589 123.807 127.94 125.804 128.532 127.627L137.089 153.964C138.414 158.041 133.748 161.431 130.28 158.911L107.877 142.634C106.326 141.507 104.226 141.507 102.675 142.634L80.2718 158.911C76.804 161.431 72.1381 158.041 73.4627 153.964L82.02 127.627C82.6124 125.804 81.9635 123.807 80.4126 122.68L58.0092 106.403C54.5414 103.884 56.3236 98.3985 60.6101 98.3985H88.3022C90.2192 98.3985 91.9181 97.1642 92.5104 95.3411L101.068 69.0042Z"
        fill="#AAB2C5"
      />
      <path
        d="M90.891 57.0575C92.2156 52.9808 97.9829 52.9808 99.3075 57.0575L107.865 83.3943C108.457 85.2174 110.156 86.4517 112.073 86.4517H139.765C144.052 86.4517 145.834 91.9368 142.366 94.4563L119.963 110.733C118.412 111.86 117.763 113.857 118.355 115.68L126.913 142.017C128.237 146.094 123.571 149.484 120.104 146.964L97.7001 130.687C96.1493 129.561 94.0493 129.561 92.4984 130.687L70.095 146.964C66.6272 149.484 61.9614 146.094 63.2859 142.017L71.8433 115.68C72.4357 113.857 71.7867 111.86 70.2359 110.733L47.8324 94.4563C44.3647 91.9368 46.1469 86.4517 50.4333 86.4517H78.1255C80.0424 86.4517 81.7413 85.2174 82.3337 83.3943L90.891 57.0575Z"
        fill="#F1F3F9"
        stroke="#AAB2C5"
        strokeWidth="1.39839"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="200" height="211.111" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BurstStar;

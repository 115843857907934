import {css, Theme} from '@emotion/react';
import styled from '@emotion/styled';

import {TextFieldVariant} from './TextField';

interface WrapperProps {
  error: boolean;
  valid: boolean;
  disabled: boolean;
  hidden: boolean;
  variant: TextFieldVariant;
}

export const Input = styled.input`
  ${({theme}) => css`
    background-color: ${theme.colors.gray1};
    border: 2px solid transparent;
    border-radius: 16px;
    color: ${theme.colors.gray8};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.averta};
    font-weight: ${theme.font.weight.normal};
    height: 50px;
    line-height: 17.3px;
    outline: none;
    padding: 16px 24px 14px;
    transition: border-color ${theme.transition.default};
    width: 100%;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      filter: none;
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.gray1} inset;
      border-color: ${theme.colors.gray1};

      &::first-line {
        font-size: ${theme.font.sizes.small};
        font-family: ${theme.font.family.averta};
        font-weight: ${theme.font.weight.normal};
      }
    }

    &:focus-within,
    &:-webkit-autofill:focus-within {
      border-color: ${theme.colors.brandColor2};
    }

    &::placeholder {
      color: ${theme.colors.gray3};
    }
  `}
`;

export const Label = styled.label`
  ${({theme}) => css`
    color: ${theme.colors.gray4};
    cursor: pointer;
    font-size: ${theme.font.sizes.xsmall};
    line-height: 15px;
  `}
`;

export const ErrorWrapper = styled.span`
  ${({theme}) => css`
    align-items: center;
    color: ${theme.colors.red};
    display: flex;
    margin-top: 8px;

    svg {
      margin-right: 5px;
      width: 15px;
    }
  `}
`;

export const Error = styled.p`
  ${({theme}) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xxsmall};
  `}
`;

const wrapperModifiers = {
  disabled: () => css`
    .TextFieldStyles__Label,
    .TextFieldStyles__Input {
      cursor: not-allowed;
      opacity: 0.75;

      &::placeholder {
        color: currentColor;
      }
    }
  `,

  hidden: () => css`
    display: none;
  `,

  valid: (theme: Theme) => css`
    .TextFieldStyles__Input,
    .TextFieldStyles__Input:hover:not(:focus) {
      border-color: ${theme.colors.brandColor1};
    }
  `,

  error: (theme: Theme) => css`
    .TextFieldStyles__Input {
      border-color: ${theme.colors.red};
    }
  `,

  // variant modifiers
  lighterBackground: (theme: Theme) => css`
    .TextFieldStyles__Input {
      background-color: ${theme.colors.lightBg};

      &:disabled {
        opacity: 1;
        background-color: ${theme.colors.gray2};
        color: ${theme.colors.gray4};
        cursor: not-allowed;
      }

      &::placeholder {
        color: ${theme.colors.gray3};
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        filter: none;
        -webkit-box-shadow: 0 0 0 30px ${theme.colors.lightBg} inset;
        border-color: ${theme.colors.lightBg};

        &::first-line {
          font-size: ${theme.font.sizes.small};
          font-family: ${theme.font.family.averta};
          font-weight: ${theme.font.weight.normal};
        }
      }

      &:focus-within,
      &:-webkit-autofill:focus-within {
        border-color: ${theme.colors.brandColor2};
      }
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({theme, disabled, error, valid, variant, hidden}) => css`
    display: flex;
    flex-direction: column;

    .TextFieldStyles__Label + .TextFieldStyles__Input {
      margin-top: 8px;
    }

    ${variant !== 'default' && wrapperModifiers[variant](theme)}
    ${disabled && wrapperModifiers.disabled()}
    ${hidden && wrapperModifiers.hidden()}
    ${valid && wrapperModifiers.valid(theme)}
    ${error && wrapperModifiers.error(theme)}
  `}
`;

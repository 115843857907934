import React from 'react';

const Assignment = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Two papers, one above the other, the one at the top is bent"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 22"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.074 21.416c.233-.083.443-.269.56-.496l.103-.2.01-8.266c.008-5.6-.002-8.318-.031-8.425-.066-.244-.28-.495-.531-.621l-.223-.113h-1.994l-.013-1.034c-.012-.968-.019-1.044-.102-1.198a1.125 1.125 0 0 0-.466-.456c-.138-.073-.336-.076-4.81-.076H4.913l-.36.128a4.14 4.14 0 0 0-.592.266C3.722 1.067.834 3.735.581 4.046c-.198.245-.41.685-.502 1.041-.077.299-.08.518-.079 6.517 0 4.15.014 6.258.043 6.36.067.24.281.49.53.615l.223.113H2.79l.013 1.034c.011.915.021 1.05.09 1.177.106.2.265.36.457.464l.164.089 6.7.008c5.625.008 6.727 0 6.86-.048Zm-13.462-.785c-.044-.053-.057-.27-.057-.994v-.925l5.344-.02 5.344-.02.165-.09c.215-.115.366-.277.469-.505.083-.183.084-.23.085-7.117V4.028l.94.012c.721.01.955.026 1.007.069.063.052.068.683.068 8.258 0 7.402-.006 8.209-.065 8.267-.058.058-.718.064-6.654.064-6.077 0-6.594-.005-6.646-.067ZM.904 17.9l-.103-.051V5.322l1.624-.02L4.05 5.28l.232-.113c.257-.126.514-.38.659-.65.094-.176.096-.207.116-1.694l.02-1.515h9.041l.053.102c.041.082.05 1.732.042 8.245l-.01 8.142-.097.078c-.095.077-.258.079-6.598.078-5.158 0-6.521-.011-6.603-.052Zm10.437-2.788c.226-.125.266-.37.09-.567l-.12-.133H3.66l-.112.111c-.163.163-.155.372.02.528l.133.118 3.77-.001c3.052-.002 3.79-.012 3.87-.056Zm.014-2.526c.04-.023.101-.097.137-.165a.347.347 0 0 0-.094-.445c-.104-.082-.16-.083-3.905-.083-2.643 0-3.824.014-3.882.044a.426.426 0 0 0-.142.185c-.05.121-.05.158 0 .28.033.077.096.16.142.184.112.06 7.641.06 7.744 0Zm.043-2.577a.415.415 0 0 0 .132-.202c.03-.138-.062-.346-.183-.41-.113-.06-7.623-.059-7.736.002-.11.058-.198.274-.17.417.012.062.068.15.125.194.101.08.18.081 3.915.081 3.757 0 3.813 0 3.917-.082Zm-.11-2.455c.114-.047.263-.237.263-.337a.44.44 0 0 0-.236-.356c-.084-.035-.692-.045-2.245-.036l-2.13.011-.1.115a.376.376 0 0 0 0 .507l.1.115 2.13.008c1.172.005 2.17-.008 2.218-.027ZM1.216 4.476c.172-.193 3.03-2.86 3.065-2.86.023 0 .034.503.026 1.267-.013 1.437-.007 1.408-.362 1.59-.176.09-.213.091-1.494.091H1.138l.078-.088Z"
      fill="currentColor"
    />
  </svg>
);

export default Assignment;

import React from 'react';

const LineChart = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    viewBox="0 0 163 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="A line chart with 5 verticies that has its center data point highlighted"
    {...props}>
    <rect x="56" width="44.628" height="125.831" rx="8.03385" fill="#EBF3FF" />
    <path
      d="M1 76.3345L42.9013 83.2483L80.9665 46.1783L116.966 71.7743L162.113 40"
      stroke="#BECCE1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LineChart;

import React from 'react';

const AlertCircleEmpty = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="A white circle with a bluish exclamation mark inside."
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102 102"
    {...props}>
    <circle r="50.834" transform="matrix(1 0 0 -1 50.834 50.834)" fill="#fff" />
    <rect x="50.43" y="28" width="1" height="36" rx=".5" fill="#9FC2F9" />
    <rect x="50.43" y="72" width="1" height="4" rx=".5" fill="#9FC2F9" />
  </svg>
);

export default AlertCircleEmpty;

import React from 'react';

const CheckCircle = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Green circle icon with a white checkmark in the middle"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 90"
    {...props}>
    <circle cx="45" cy="45" r="45" fill="#00CFAA" />
    <path
      d="m40.5 54.346 19.038-19.038M40.5 54.346 30.115 43.962"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default CheckCircle;

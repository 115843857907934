import {useCallback, useState, useMemo} from 'react';

/**
 * React hook to manage boolean (true - false) states
 *
 * @param initialState the initial boolean state value
 */
export function useBoolean(initialState = false) {
  const [value, setValue] = useState<boolean>(initialState);

  const setCustomValue = useCallback((customValue: boolean) => {
    setValue(customValue);
  }, []);

  const setTrue = useCallback(() => {
    setValue(true);
  }, []);

  const setFalse = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue(previousValue => !previousValue);
  }, []);

  const setState = useMemo(() => {
    return {setValue: setCustomValue, setTrue, setFalse, toggle} as const;
  }, [setCustomValue, setFalse, setTrue, toggle]);

  return [value, setState] as const;
}

function encode<T = unknown>(value: T): string {
  return JSON.stringify(value);
}

function decode<T = unknown>(value: string): T {
  return JSON.parse(value) as T;
}

export class StorageAdapter<TEntity extends Record<string, any>> {
  private storage?: Storage;

  constructor(storage?: Storage) {
    this.storage = storage;
  }

  getItem<TKey extends keyof TEntity>(key: TKey): TEntity[TKey] | undefined {
    const encoded = this.storage?.getItem(key.toString());
    if (!encoded) return undefined;
    return decode<TEntity[TKey]>(encoded);
  }

  setItem<TKey extends keyof TEntity>(key: TKey, value: TEntity[TKey]): void {
    this.storage?.setItem(key.toString(), encode(value));
  }

  removeItem<TKey extends keyof TEntity>(key: TKey): void {
    this.storage?.removeItem(key.toString());
  }

  clear(): void {
    this.storage?.clear();
  }
}

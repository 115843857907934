import {Global, css, Theme} from '@emotion/react';

interface GlobalStylesProps {
  theme: Theme;
}

const GlobalStyles = ({theme}: GlobalStylesProps) => (
  <Global
    styles={css`
      @font-face {
        font-family: Averta Standard;
        src: url('/fonts/eot/Averta-Standard-Black.eot');
        src: url('/fonts/eot/Averta-Standard-Black.eot?#iefix') format('embedded-opentype'),
          url('/fonts/woff2/Averta-Standard-Black.woff2') format('woff2'),
          url('/fonts/woff/Averta-Standard-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: Averta Standard;
        src: url('/fonts/eot/Averta-Standard-Bold.eot');
        src: url('/fonts/eot/Averta-Standard-Bold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/woff2/Averta-Standard-Bold.woff2') format('woff2'),
          url('/fonts/woff/Averta-Standard-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: Averta Standard;
        src: url('/fonts/eot/Averta-Standard-Semibold.eot');
        src: url('/fonts/eot/Averta-Standard-Semibold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/woff2/Averta-Standard-Semibold.woff2') format('woff2'),
          url('/fonts/woff/Averta-Standard-Semibold.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: Averta Standard;
        src: url('/fonts/eot/hinted-AvertaStd-Regular.eot');
        src: url('/fonts/eot/hinted-AvertaStd-Regular.eot?#iefix') format('embedded-opentype'),
          url('/fonts/woff2/Averta-Standard-Regular.woff2') format('woff2'),
          url('/fonts/woff/hinted-AvertaStd-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: Averta Standard;
        src: url('/fonts/eot/Averta-Standard-Light.eot');
        src: url('/fonts/eot/Averta-Standard-Light.eot?#iefix') format('embedded-opentype'),
          url('/fonts/woff2/Averta-Standard-Light.woff2') format('woff2'),
          url('/fonts/woff/Averta-Standard-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &::before,
        &::after {
          box-sizing: border-box;
        }
      }

      body {
        display: flex;
        justify-content: center;
        min-width: 1440px;
        background-color: ${theme.colors.mainBg};
        font-family: ${theme.font.family.averta};
        font-size: ${theme.font.sizes.medium};
      }

      #__next {
        width: 100%;
      }

      input,
      button,
      textarea,
      select {
        font-family: inherit;
      }
      a {
        text-decoration: none;
      }

      *:focus,
      *:focus-visible {
        outline: ${theme.focus.default};
      }

      ::-moz-selection {
        background: ${theme.colors.brandColor1};
        text-shadow: none;
      }

      ::selection {
        background: ${theme.colors.brandColor1};
        color: ${theme.colors.black};
        text-shadow: none;
      }
    `}
  />
);

export default GlobalStyles;

import React, {useState, InputHTMLAttributes} from 'react';

import {AlertTriangleIcon} from '@src/components/icons';
import InputMasks from '@src/utils/inputMasks';
import {useBoolean} from '@src/hooks/useBoolean';

import * as S from './TextField.styles';

type AvailableInputMasks = keyof typeof InputMasks;
export type TextFieldVariant = 'default' | 'lighterBackground';

export interface TextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string;
  initialValue?: string;
  error?: string;
  isValid?: boolean;
  variant?: TextFieldVariant;
  mask?: AvailableInputMasks;
  onChange?: (value: string, name: string) => void;
}

const TextField = ({
  name,
  label,
  initialValue = '',
  error,
  isValid = false,
  variant = 'default',
  mask,
  type = 'text',
  disabled = false,
  required = false,
  onChange,
  onBlur,
  ...props
}: TextFieldProps) => {
  const [value, setValue] = useState(initialValue);
  const [hasTypedAnyValue, setHasTypedAnyValue] = useBoolean(false);
  const [visited, setVisited] = useBoolean(false);

  const formattedLabel = required ? `${label}*` : label;
  const showError = !!error && (visited || hasTypedAnyValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (mask && InputMasks[mask]) {
      InputMasks[mask](event);
    }

    const newValue = event.currentTarget.value;
    if (newValue === value) return;

    setValue(newValue);
    onChange?.(newValue, name);
    !hasTypedAnyValue && setHasTypedAnyValue.setTrue();
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    !visited && setVisited.setTrue();
    onBlur?.(event);
  };

  return (
    <S.Wrapper
      className="TextFieldStyles__Wrapper"
      disabled={disabled}
      valid={isValid}
      error={showError}
      variant={variant}
      hidden={type === 'hidden'}>
      {!!label && (
        <S.Label className="TextFieldStyles__Label" htmlFor={name}>
          {formattedLabel}
        </S.Label>
      )}

      <S.Input
        className="TextFieldStyles__Input"
        type={type}
        value={value}
        disabled={disabled}
        required={required}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        {...(label ? {id: name} : {})}
        {...props}
      />

      {showError && (
        <S.ErrorWrapper>
          <AlertTriangleIcon />
          <S.Error>{error}</S.Error>
        </S.ErrorWrapper>
      )}
    </S.Wrapper>
  );
};

export default TextField;

import React from 'react';

const PadlockOutline = (props: React.SVGAttributes<HTMLOrSVGElement>) => (
  <svg
    role="img"
    aria-label="Padlock with an outline style"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 56"
    {...props}>
    <rect x="1.355" y="21.804" width="42.29" height="33.333" rx="8.457" stroke="currentColor" />
    <path
      d="M24.596 42.063c.067.337-.168.64-.505.64h-3.066c-.336 0-.572-.303-.505-.64l1.01-3.975a2.422 2.422 0 0 1-1.448-2.223 2.473 2.473 0 0 1 2.46-2.46A2.473 2.473 0 0 1 25 35.866c0 .977-.606 1.819-1.448 2.223l1.044 3.975Z"
      fill="currentColor"
    />
    <path d="M36.68 22.05v-7.277c0-7.832-6.348-14.18-14.18-14.18S8.318 6.94 8.318 14.772v7.278" stroke="currentColor" />
  </svg>
);

export default PadlockOutline;

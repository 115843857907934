export default {
  colors: {
    brandColor1: '#00CFAA',
    brandColor1Light: '#EBFBF8',
    brandColor1Darkened: '#00A688',

    brandColor2: '#0065FF',
    brandColor2Light: '#E0ECFF',
    brandColor2Darkened: '#0051CC',

    brandColor3: '#FF6C90',
    brandColor3Light: '#FFE2E9',

    mainBg: '#F8F8F9',
    lightBg: '#FFFFFF',

    white: '#FFFFFF',
    black: '#000000',
    red: '#EB5757',
    redDarkened: '#BC4646',

    gray1: '#F8F8F9',
    gray2: '#EEEEEE',
    gray3: '#D6D6D6',
    gray4: '#8F8E9B',
    gray5: '#D0D3DD',
    gray6: '#9AA0B5',
    gray7: '#364161',
    gray8: '#333333',
  },

  border: {
    radius: '4px',
  },

  font: {
    family: {
      averta:
        'Averta Standard, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    },
    weight: {
      thin: 100,
      light: 300,
      normal: 400,
      semiBold: 600,
      bold: 700,
      bolder: 800,
    },
    sizes: {
      xxsmall: '10px',
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xlarge: '20px',
      xxlarge: '36px',
      huge: '50px',
      error: '180px',
    },
  },

  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },

  breakpoints: {
    medium: '768px',
    large: '1024px',
  },

  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },

  focus: {
    default: '5px auto #0065FF',
  },
} as const;

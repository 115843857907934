import AddIcon from './Add';
import AddCircleIcon from './AddCircle';
import AlertCircleEmptyIcon from './AlertCircleEmpty';
import AlertCircleIcon from './AlertCircle';
import AlertCircleFullIcon from './AlertCircleFull';
import AlertTriangleIcon from './AlertTriangle';
import ArrowLeftIcon from './ArrowLeft';
import ArrowRightIcon from './ArrowRight';
import AssignmentIcon from './Assignment';
import AttentionFIcon from './AttentionF';
import AttentionPaperIcon from './AttentionPaper';
import AttentionXIcon from './AttentionX';
import BurstAlertIcon from './BurstAlert';
import BurstAssignmentIcon from './BurstAssignment';
import BurstDeleteIcon from './BurstDelete';
import BurstStarIcon from './BurstStar';
import CalendarIcon from './Calendar';
import CaretIcon from './Caret';
import CategoriesIcon from './Categories';
import CheckCircleIcon from './CheckCircle';
import CheckedAssignmentIcon from './CheckedAssignment';
import CheckMarkIcon from './CheckMark';
import CleverIcon from './Clever';
import CloseIcon from './Close';
import DashboardIcon from './Dashboard';
import FolderIcon from './Folder';
import FolderOutlineIcon from './FolderOutline';
import FolderOutlineOpenIcon from './FolderOutlineOpen';
import GearIcon from './Gear';
import KeyOutlineIcon from './KeyOutline';
import LateSubmissionIcon from './LateSubmission';
import LineChartIcon from './LineChart';
import LogoutIcon from './Logout';
import LowPerformanceIcon from './LowPerformance';
import MailVerifiedIcon from './MailVerified';
import MeterAboveAverageIcon from './MeterAboveAverage';
import MeterAverageIcon from './MeterAverage';
import MeterBelowAverageIcon from './MeterBelowAverage';
import MeterEmptyIcon from './MeterEmpty';
import PadlockConfirmIcon from './PadlockConfirm';
import PadlockOutlineIcon from './PadlockOutline';
import PersonCircleIcon from './PersonCircle';
import PaperIcon from './Paper';
import RemoveIcon from './Remove';
import SearchIcon from './Search';
import SearchCircleBlueIcon from './SearchCircleBlue';
import SearchCircleIcon from './SearchCircle';
import SunIcon from './Sun';
import TrashIcon from './Trash';
import TrashCircleIcon from './TrashCircleIcon';
import TrashOutlineIcon from './TrashOutline';

export {
  AddIcon,
  AddCircleIcon,
  AlertCircleEmptyIcon,
  AlertCircleIcon,
  AlertCircleFullIcon,
  AlertTriangleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AssignmentIcon,
  AttentionFIcon,
  AttentionPaperIcon,
  AttentionXIcon,
  BurstAlertIcon,
  BurstAssignmentIcon,
  BurstDeleteIcon,
  BurstStarIcon,
  CalendarIcon,
  CaretIcon,
  CategoriesIcon,
  CheckCircleIcon,
  CheckedAssignmentIcon,
  CheckMarkIcon,
  CleverIcon,
  CloseIcon,
  DashboardIcon,
  FolderIcon,
  FolderOutlineIcon,
  FolderOutlineOpenIcon,
  GearIcon,
  KeyOutlineIcon,
  LateSubmissionIcon,
  LineChartIcon,
  LogoutIcon,
  LowPerformanceIcon,
  MailVerifiedIcon,
  MeterAboveAverageIcon,
  MeterAverageIcon,
  MeterBelowAverageIcon,
  MeterEmptyIcon,
  PadlockConfirmIcon,
  PadlockOutlineIcon,
  PersonCircleIcon,
  PaperIcon,
  RemoveIcon,
  SearchIcon,
  SearchCircleBlueIcon,
  SearchCircleIcon,
  SunIcon,
  TrashIcon,
  TrashCircleIcon,
  TrashOutlineIcon,
};
